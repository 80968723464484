import type { ElementReviewStatus } from '@/models/standardAds/moderation';
import type { ItemDecoded, ItemStandardAds, Task } from '@/models/task';

export type AdGroupId = string;
export type AdGroupName = string;
export type CampaignId = string;
export type CampaignName = string;

export interface TaskStandardAds extends Omit<Task, 'payload' | 'items'> {
  payload: ReviewRequest;
  items?: ItemStandardAds<CampaignCreativeRequest>[];
}

export interface ReviewRequest {
  reviewRequestId: string;
  proposedAt?: number;
  advertiserId: number;
  advertiserName?: string;
  advertiserReviewNote?: string;
  brandId: number;
  brandName?: string;
  brandReviewNote?: string;
  companyId: number;
  companyName?: string;
  adCategoryId: number;
  adCategoryName?: string;
  adPolicyCategories: number[];
  taskPool?: string;
  campaigns: Record<CampaignId, CampaignName>;
  adGroups?: Record<AdGroupId, AdGroupName>;
  campaignCreativeTypes: CampaignCreativeType[];
  reviewWorkflow?: string;
  schema?: string;
  region?: string;
}

export type CampaignCreativeRequest =
  | CampaignCreativeImageRequest
  | CampaignCreativeVideoRequest
  | CampaignCreativeCarouselRequest
  | CampaignCreativeCatalogImageRequest
  | CampaignCreativeCatalogCarouselRequest;

interface CampaignCreativeRequestBase<T extends string, A extends AssetBase> {
  key: number;
  campaignCreativeType: T;
  revision?: number;
  revisionId: number;
  campaignCreativeId?: number;
  adId?: number;
  creativeId: number;
  campaignId: number;
  adGroupId?: number;
  title: string;
  destination: string;
  sponsor: string;
  body: string;
  assets: A[];
  titleScore?: number;
  bodyScore?: number;
  titleMlVersion?: string;
  bodyMlVersion?: string;
  schema?: string;
  statusCode?: number;
  indexKey?: string;
}

export interface ElementsReviewedData {
  elementsReviewStatus?: ElementsReviewedStatus;
  elementsReviewComment?: ElementsReviewedContent;
  elementsReviewInternalComment?: ElementsReviewedContent;
  rejectReasonCodes?: RejectReasonCodes;
}

export interface ElementsReviewedStatus {
  title?: ElementReviewStatus;
  destination?: ElementReviewStatus;
  sponsor?: ElementReviewStatus;
  body?: ElementReviewStatus;
  image?: any;
  video?: any;
  carouselTitle?: any;
}

export interface ElementsReviewedContent {
  title?: string;
  destination?: string;
  sponsor?: string;
  body?: string;
  image?: any;
  video?: any;
  carouselTitle?: any;
}

export interface RejectReasonCodes {
  creative?: string[];
  title?: string[];
  destination?: string[];
  sponsor?: string[];
  body?: string[];
  image?: Map<string, string[]>;
  video?: Map<string, string[]>;
  carouselTitle?: Map<string, string[]>;
}

export type CampaignCreativeImageRequest = CampaignCreativeRequestBase<
  'standard_image',
  ImageAsset
>;

export type CampaignCreativeVideoRequest = CampaignCreativeRequestBase<
  'standard_video',
  VideoAsset
>;

export type CampaignCreativeCarouselRequest = CampaignCreativeRequestBase<
  'carousel',
  CarouselAsset
>;

export type CampaignCreativeCatalogImageRequest = CampaignCreativeRequestBase<
  'catalog_image',
  ImageAsset
>;

export type CampaignCreativeCatalogCarouselRequest = CampaignCreativeRequestBase<
  'catalog_carousel',
  CarouselAsset
>;

export type CampaignCreativeType = CampaignCreativeRequest['campaignCreativeType'];

export type ImageType = 'icon' | 'a' | 'b' | 'c' | 'd' | 'e' | 'unknown';
export type VideoQuality = 'original' | 'high' | 'middle' | 'low';

export type MediaId = number;

export interface AssetBase {
  width: number;
  height: number;
  url: string;
  sha1?: string;
}

export interface ImageLikeAsset extends AssetBase {
  imageId: MediaId;
  imageType: ImageType;
  imageScore?: number;
  imageMlVersion?: string;
}

export interface ImageAsset extends ImageLikeAsset {
  type: 'image';
}

export interface VideoAsset extends AssetBase {
  type: 'video';
  videoId: MediaId;
  videoQuality: VideoQuality;
  thumbnailUrl?: string;
}

export interface CarouselAsset extends ImageLikeAsset {
  type: 'carousel';
  imageTitle: string;
}

const campaignCreativeTypeOrderMap: Record<CampaignCreativeType, number> = {
  standard_image: 0,
  standard_video: 1,
  carousel: 2,
  catalog_image: 3,
  catalog_carousel: 4,
};

export const isItemStandardAds = (e: ItemDecoded): e is ItemStandardAds<CampaignCreativeRequest> =>
  e.namespace === 'standard_ads' || e.namespace === 'standard_ads_us';

export const isItemStandardAdsOf =
  <T extends CampaignCreativeRequest = CampaignCreativeRequest>(
    f: (e: CampaignCreativeRequest) => e is T,
  ) =>
  (e: ItemStandardAds<CampaignCreativeRequest>): e is ItemStandardAds<T> =>
    f(e.payload);

export const isCampaignCreativeImageRequest = (
  e: CampaignCreativeRequest,
): e is CampaignCreativeImageRequest => e.campaignCreativeType === 'standard_image';

export const isCampaignCreativeVideoRequest = (
  e: CampaignCreativeRequest,
): e is CampaignCreativeVideoRequest => e.campaignCreativeType === 'standard_video';

export const isCampaignCreativeCarouselRequest = (
  e: CampaignCreativeRequest,
): e is CampaignCreativeCarouselRequest => e.campaignCreativeType === 'carousel';

export const campaignCreativeTypeToHumanReadableString = (
  campaignCreativeType: CampaignCreativeType,
): string => {
  switch (campaignCreativeType) {
    case 'standard_image':
    case 'catalog_image':
      return 'Image';
    case 'standard_video':
      return 'Video';
    case 'carousel':
    case 'catalog_carousel':
      return 'Story';
    default:
      return campaignCreativeType;
  }
};

export const sortItemsByDefaultOrder = (
  items: ItemStandardAds<CampaignCreativeRequest>[],
): ItemStandardAds<CampaignCreativeRequest>[] =>
  items.sort((a, b) => {
    const deleteFirst = (a.isDeleted ? 0 : 1) - (b.isDeleted ? 0 : 1);
    if (deleteFirst !== 0) return deleteFirst;

    const aType = a.payload.campaignCreativeType;
    const bType = b.payload.campaignCreativeType;
    return campaignCreativeTypeOrderMap[aType] - campaignCreativeTypeOrderMap[bType];
  });
