import { objectSnakeToCamel } from '@/models/decode';
import type {
  CampaignCreativeRequest,
  ReviewRequest,
  TaskStandardAds,
} from '@/models/standardAds/reviewRequest';
import { isItemStandardAds } from '@/models/standardAds/reviewRequest';
import type { ItemDecoded, ItemStandardAds, Task } from '@/models/task';
import { decodeItem } from '@/models/task';

export const decodeItemStandardAds = (
  item: ItemDecoded,
): ItemStandardAds<CampaignCreativeRequest> => {
  if (!isItemStandardAds(item)) {
    throw new Error('given item is not StandardAds item');
  }
  const request = objectSnakeToCamel(item.payload);
  const assets = Object.values(request.assets);
  const refinedRequest = {
    ...request,
    assets,
  };
  return {
    ...item,
    payload: refinedRequest as CampaignCreativeRequest,
  };
};

export const decodeTaskStandardAds = (task: Task): TaskStandardAds => {
  const reviewRequest = objectSnakeToCamel(JSON.parse(task.payload)) as ReviewRequest;
  const items = task.items?.map(decodeItem)?.map(decodeItemStandardAds);

  return {
    ...task,
    payload: reviewRequest,
    items,
  };
};
