import { PolicyCategory } from '@/models/adPolicyCategory';
import { moderationV3Request } from '@/pages/utils/service';

export interface GetChannelWithPolicyCategoryResponse {
  list: ChannelWithPolicyCategory[];
}

export interface UpdateChannelWithPolicyCategoryRequest {
  channelId: number;
  categoryIds: number[];
}

export interface ChannelInformation {
  id: number;
  mediaId: number;
  mediaLabel: string;
  label: string;
  aliasLabel: string;
  restrictMode: string;
  allowFirstView: string;
  enable: string;
}

export interface ChannelWithPolicyCategory {
  channel: ChannelInformation;
  categories: PolicyCategory[];
}

export async function getAllAdChannelsWithPolicyCategories(
  namespace: string,
): Promise<GetChannelWithPolicyCategoryResponse> {
  return moderationV3Request(`${namespace}/channels/policy-categories`, 'GET');
}

export async function updateAdChannelWithPolicyCategory(
  namespace: string,
  channel: UpdateChannelWithPolicyCategoryRequest,
): Promise<string> {
  return moderationV3Request(
    `${namespace}/channels/policy-categories/${channel.channelId}`,
    'POST',
    channel,
  );
}
