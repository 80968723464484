import type { PastReviewResult } from '@/pages/services/interface/StandardAdsElementPassRate';
import { moderationV3APICommon } from '@/pages/services/moderationV3';
import { logError } from '@/utils/utils';
import { message } from 'antd';
import type { Effect, Reducer } from 'umi';

const { pastReviewResultHistory, pastReviewResultSimilar } = moderationV3APICommon;

export interface StandardAdsPastReviewResultStateType {
  pastReviewResults: PastReviewResult[];
  loading: boolean;
}

export interface StandardAdsPastReviewResultModelType {
  namespace: 'standardAdsPastReviewResults';
  state: StandardAdsPastReviewResultStateType;
  effects: {
    fetchHistory: Effect;
    fetchSimilar: Effect;
  };
  reducers: {
    saveReviewResults: Reducer<StandardAdsPastReviewResultStateType>;
    setLoading: Reducer<StandardAdsPastReviewResultStateType>;
  };
}

const StandardAdsPastReviewResultModel: StandardAdsPastReviewResultModelType = {
  namespace: 'standardAdsPastReviewResults',

  state: {
    pastReviewResults: [],
    loading: false,
  },

  effects: {
    *fetchHistory(
      {
        payload: {
          namespace: namespace,
          advertiserId: advertiserId,
          brandId: brandId,
          hashCode: hashCode,
          fieldName: fieldName,
          value: value,
        },
      },
      { call, put },
    ) {
      try {
        yield put({
          type: 'setLoading',
        });

        const historyResponse = yield call(pastReviewResultHistory, namespace, fieldName, {
          filter: { advertiserId: advertiserId, brandId: brandId, hashCode: hashCode },
          values: [value],
        });

        if (historyResponse.status >= 400)
          throw new Error(
            `pastReviewResultHistory(namespace = ${namespace}, fieldName = ${fieldName}, value = ${value}) failed with ${historyResponse.status}: ${historyResponse.data}`,
          );

        yield put({
          type: 'saveReviewResults',
          payload: historyResponse.data[value],
        });
      } catch (e) {
        message.error('Failed to fetch past review results history: ' + e);
        const context = e instanceof Error ? e : null;
        logError('Failed to fetch past review results history: ' + e, context);
      }
    },
    *fetchSimilar(
      {
        payload: {
          namespace: namespace,
          advertiserId: advertiserId,
          brandId: brandId,
          fieldName: fieldName,
          value: value,
        },
      },
      { call, put },
    ) {
      try {
        const similarResponse = yield call(pastReviewResultSimilar, namespace, fieldName, {
          filter: { advertiserId: advertiserId, brandId: brandId },
          values: [value],
        });

        if (similarResponse.status >= 400)
          throw new Error(
            `pastReviewResultSimilar(namespace = ${namespace}, fieldName = ${fieldName}, value = ${value}) failed with ${similarResponse.status}: ${similarResponse.data}`,
          );

        yield put({
          type: 'saveReviewResults',
          payload: similarResponse.data[value],
        });
      } catch (e) {
        message.error('Failed to fetch similar past review results: ' + e);
        const context = e instanceof Error ? e : null;
        logError('Failed to fetch similar past review results: ' + e, context);
      }
    },
  },

  reducers: {
    saveReviewResults(state, action) {
      return {
        ...state,
        pastReviewResults: action.payload,
        loading: false,
      };
    },
    setLoading(state) {
      return {
        ...state,
        loading: true,
      };
    },
  },
};

export default StandardAdsPastReviewResultModel;
