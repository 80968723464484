export default {
  'menu.DocomoContent': 'SFD Content',
  'sfd.article.reviewResult': 'Review Result',
  'sfd.article.search.mode.simple': 'Simple Search',
  'sfd.article.search.mode.advanced': 'Advanced Search',
  'sfd.article.reviewResult.All': 'All',
  'sfd.article.reviewResult.Unchecked': 'Unchecked',
  'sfd.article.reviewResult.Checked': 'Checked',
  'sfd.article.reviewResult.Pending': 'Pending',
  'sfd.article.reviewResult.Marked': 'Marked',
  'sfd.article.reviewResult.Stopped': 'Stopped',
  'sfd.article.channel': 'Channel',
  'sfd.article.publisher': 'Publisher',
  'sfd.article.category': 'Category',
  'sfd.article.channel.search.placeholder': 'Search channel',
  'sfd.article.publisher.search.placeholder': 'Search publisher',
  'sfd.article.category.search.placeholder': 'Search category',
  'sfd.article.lastUpdated': 'Last updated',
  'sfd.article.keyword.search.placeholder': 'Search Keywords / URL',
  'sfd.article.keyword.search': 'Keywords',
  'sfd.article.search': 'Search',
  'sfd.article.reset': 'Reset',
  'sfd.article.orderBy': 'Order by',
  'sfd.article.lastUpdatedTime': 'Last updated time',
  'sfd.article.desc': 'DESC',
  'sfd.article.asc': 'ASC',
  'sfd.article.ngReason': 'Rejection reason',
  'sfd.article.ngReason.placeholder': 'Select rejection reason',
  'sfd.article.comment': 'Comment',
  'sfd.article.comment.placeholder': 'Select existence',
  'sfd.article.comment.exist': 'Comment Exist',
  'sfd.article.comment.noExist': 'Comment do not Exist',
  'sfd.article.searchFor': 'Search for',
  'sfd.article.selectObject': 'Select object',
  'sfd.article.title': 'Title',
  'sfd.article.content': 'Content',
  'sfd.article.url': 'Url',
  'sfd.article.published': 'Published',
  'sfd.article.preview.loading': 'Loading...',
  'sfd.article.moderation.comment.placeholder': 'Input Comment',
  'sfd.article.moderation.button.check': 'Check',
  'sfd.article.moderation.button.mark': 'Mark',
  'sfd.article.moderation.button.stop': 'Stop',
  'sfd.article.moderation.button.pend': 'Pending',
  'sfd.article.moderation.success.message': 'Operation success',
  'sfd.article.moderation.button.reviewAll': 'Review All',
  'sfd.article.moderation.reviewAll.dialog.confirm': 'Confirm',
  'sfd.article.moderation.reviewAll.dialog.cancel': 'Cancel',
  'sfd.article.ngReason.eroticImage': 'Sexually Explicit Image',
  'sfd.article.ngReason.grotesqueImage': 'Graphic/Gory Image',
  'sfd.article.ngReason.lowResolutionImage': 'Low Resolution Image',
  'sfd.article.ngReason.pastArticle': 'Old Article',
  'sfd.article.ngReason.others': 'Others',
  'sfd_content.article.updated': 'Article has been updated, please refresh the page again',
  'sfd_content.article.reviewed':
    'Article has been reviewed by other moderator, please refresh the page again',
  'sfd_content.article.updated.show.only': 'Show only updated articles',
  'sfd_content.article.export': 'Export',
  'sfd_content.article.export.button.request': 'Request',
  'sfd_content.article.export.button.reset': 'Reset',
  'sfd_content.article.export.reviewTimePeriod': 'Review Time Period',
  'sfd_content.article.export.status': 'Status',
  'sfd_content.article.export.status.pending': 'Pending',
  'sfd_content.article.export.status.readyForDownload': 'Ready For Download',
  'sfd_content.article.export.status.failed': 'Failed',
  'sfd_content.article.export.status.expiredTime': 'Expired Time',
  'sfd_content.article.export.status.requestTime': 'Request Time',
  'sfd_content.article.export.status.allRequestReports': 'ALL REQUEST REPORTS',
};
