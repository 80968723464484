import type {
  BrandWithPolicyCategory,
  GetBrandWithPolicyCategoryResponse,
} from '@/pages/services/adBrand';
import {
  getAllAdBrandsWithPolicyCategories,
  updateAdsBrandWithPolicyCategory,
} from '@/pages/services/adBrand';
import { GetPolicyCategoryResponse } from '@/pages/services/adPolicyCategory';
import type { Effect, Reducer } from 'umi';

export interface AdBrandState {
  brands: BrandWithPolicyCategory[];
}

export interface AdBrandModelType {
  namespace: 'adBrand';
  state: AdBrandState;
  effects: {
    fetchBrands: Effect;
    updateBrand: Effect;
  };
  reducers: {
    saveBrands: Reducer<AdBrandState>;
  };
}

const namespace = 'STANDARD_ADS';

const BrandModel: AdBrandModelType = {
  namespace: 'adBrand',

  state: {
    brands: [],
  },

  effects: {
    *fetchBrands(_, { call, put }) {
      const response: GetBrandWithPolicyCategoryResponse = yield call(
        getAllAdBrandsWithPolicyCategories,
        namespace,
      );

      yield put({
        type: 'saveBrands',
        payload: response.list,
      });

      return response;
    },

    *updateBrand({ payload }, { call, put, select }) {
      const response: BrandWithPolicyCategory = yield call(
        updateAdsBrandWithPolicyCategory,
        namespace,
        payload,
      );

      const { brandId, categoryIds } = payload;

      const brands: BrandWithPolicyCategory[] = yield select((state) => state.adBrand.brands);
      const categories: GetPolicyCategoryResponse[] = yield select(
        (state) => state.adPolicyCategory.categories,
      );

      const index = brands.findIndex((brand) => brand.brandId === brandId);
      const brandCategories = categoryIds.map((id: number) => {
        const category = categories.find((c) => c.id === id);
        return {
          id,
          nameJp: category?.nameJp || '',
          nameEn: category?.nameEn || '',
        };
      });

      brands[index] = {
        ...brands[index],
        policyCategories: brandCategories,
      };

      yield put({
        type: 'saveBrands',
        payload: [...brands],
      });

      return response;
    },
  },

  reducers: {
    saveBrands(state, { payload }) {
      return {
        ...state,
        brands: payload,
      };
    },
  },
};

export default BrandModel;
