import type {
  GetPolicyCategoriesResponse,
  GetPolicyCategoryResponse,
} from '@/pages/services/adPolicyCategory';
import {
  deletePolicyCategory,
  getPolicyCategories,
  updatePolicyCategories,
  updatePolicyCategory,
} from '@/pages/services/adPolicyCategory';
import type { Effect, Reducer } from 'umi';

export interface AdPolicyCategoryState {
  categories: GetPolicyCategoryResponse[];
}

export interface AdPolicyCategoryModelType {
  namespace: 'adPolicyCategory';
  state: AdPolicyCategoryState;
  effects: {
    fetchCategories: Effect;
    updateCategory: Effect;
    updateCategories: Effect;
    deleteCategory: Effect;
  };
  reducers: {
    saveCategories: Reducer<AdPolicyCategoryState>;
  };
}

export interface PolicyCategory {
  id: number;
  nameJp: string;
  nameEn: string;
}

const namespace = 'STANDARD_ADS';

const AdPolicyCategoryModel: AdPolicyCategoryModelType = {
  namespace: 'adPolicyCategory',

  state: {
    categories: [],
  },

  effects: {
    *fetchCategories(_, { call, put }) {
      const response: GetPolicyCategoriesResponse = yield call(getPolicyCategories, namespace);

      yield put({
        type: 'saveCategories',
        payload: response.list,
      });

      return response;
    },

    *updateCategory({ payload }, { call, put }) {
      const response: GetPolicyCategoryResponse = yield call(
        updatePolicyCategory,
        namespace,
        payload,
      );

      yield put({ type: 'fetchCategories' });

      return response;
    },

    *updateCategories({ payload }, { call, put }) {
      const response: GetPolicyCategoriesResponse = yield call(
        updatePolicyCategories,
        namespace,
        payload,
      );

      yield put({ type: 'fetchCategories' });

      return response;
    },

    *deleteCategory({ payload }, { call, put }) {
      yield call(deletePolicyCategory, namespace, payload, true);
      yield put({ type: 'fetchCategories' });
    },
  },

  reducers: {
    saveCategories(state, { payload }) {
      return {
        ...state,
        categories: payload,
      };
    },
  },
};

export default AdPolicyCategoryModel;
