import { moderationV3Request } from '@/pages/utils/service';

export interface GetPolicyCategoryResponse {
  id: number;
  level: number;
  nameJp: string;
  nameEn: string;
  displayOrder: number;
  type: string;
  meta?: string | null;
  parentId?: number | null;
  children?: GetPolicyCategoryResponse[];
}

export interface GetPolicyCategoriesResponse {
  list: GetPolicyCategoryResponse[];
}

export interface PostPolicyCategoryRequest {
  id?: number | null;
  level: number;
  nameJp: string;
  nameEn: string;
  type: string;
  displayOrder: number;
  meta?: string | null;
  parentId?: number | null | undefined;
  children?: PostPolicyCategoryRequest[];
  ignoreChildren: boolean;
}

export interface PostPolicyCategoriesRequest {
  list: PostPolicyCategoryRequest[];
}

export async function getPolicyCategory(
  namespace: string,
  policyCategoryId: number,
  ignoreChildren: boolean,
): Promise<GetPolicyCategoryResponse> {
  return moderationV3Request(`${namespace}/policy-categories/${policyCategoryId}`, 'GET', {
    ignoreChildren,
  });
}

export async function getPolicyCategories(namespace: string): Promise<GetPolicyCategoriesResponse> {
  return moderationV3Request(`${namespace}/policy-categories`, 'GET');
}

export async function updatePolicyCategory(
  namespace: string,
  request: PostPolicyCategoryRequest,
): Promise<GetPolicyCategoryResponse> {
  return moderationV3Request(`${namespace}/policy-categories`, 'POST', request);
}

export async function updatePolicyCategories(
  namespace: string,
  request: PostPolicyCategoriesRequest,
): Promise<GetPolicyCategoriesResponse> {
  return moderationV3Request(`${namespace}/policy-categories/bulk`, 'POST', request);
}

export async function deletePolicyCategory(
  namespace: string,
  policyCategoryId: number,
  ignoreChildren: boolean,
): Promise<string> {
  return moderationV3Request(`${namespace}/policy-categories/${policyCategoryId}`, 'DELETE', {
    ignoreChildren,
  });
}
