import { PolicyCategory } from '@/models/adPolicyCategory';
import { moderationV3Request } from '@/pages/utils/service';

export interface GetBrandWithPolicyCategoryResponse {
  list: BrandWithPolicyCategory[];
}

export interface BrandWithPolicyCategory {
  brandId: number;
  brandName: string;
  policyCategories: PolicyCategoryForBrand[];
}

export interface PolicyCategoryForBrand {
  id: number;
  nameJp: string;
  nameEn: string;
}

export interface UpdateBrandWithPolicyCategoryRequest {
  brandId: number;
  categoryIds: number[];
  policyCategories: PolicyCategory[];
}

export async function getAllAdBrandsWithPolicyCategories(
  namespace: string,
): Promise<GetBrandWithPolicyCategoryResponse> {
  return moderationV3Request(`${namespace}/brands/policy-categories`, 'GET');
}

export async function updateAdsBrandWithPolicyCategory(
  namespace: string,
  brand: UpdateBrandWithPolicyCategoryRequest,
): Promise<string> {
  return moderationV3Request(
    `${namespace}/brands/policy-categories/${brand.brandId}`,
    'POST',
    brand,
  );
}
