// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/home/circleci/project/src/models/adBrand';
import model_2 from '/home/circleci/project/src/models/adChannel';
import model_3 from '/home/circleci/project/src/models/adPolicyCategory';
import model_4 from '/home/circleci/project/src/models/setting';
import model_5 from '/home/circleci/project/src/models/user';
import model_6 from '/home/circleci/project/src/pages/dynamicAds/allow-deny-list/model';
import model_7 from '/home/circleci/project/src/pages/dynamicAds/items/model';
import model_8 from '/home/circleci/project/src/pages/dynamicAds/similar/model';
import model_9 from '/home/circleci/project/src/pages/geologic-ads/model';
import model_10 from '/home/circleci/project/src/pages/standard-ads/past-review-results/model';
import model_11 from '/home/circleci/project/src/pages/standard-ads/task-moderation/model';
import model_12 from '/home/circleci/project/src/pages/task-pools/task-pool-details/model';
import model_13 from '/home/circleci/project/src/pages/task-pools/task-pool-rules/model';
import model_14 from '/home/circleci/project/src/pages/task-user-groups/task-user-group-details/model';

export const models = {
model_1: { namespace: 'adBrand', model: model_1 },
model_2: { namespace: 'adChannel', model: model_2 },
model_3: { namespace: 'adPolicyCategory', model: model_3 },
model_4: { namespace: 'setting', model: model_4 },
model_5: { namespace: 'user', model: model_5 },
model_6: { namespace: 'dynamicAds.allow-deny-list.model', model: model_6 },
model_7: { namespace: 'dynamicAds.items.model', model: model_7 },
model_8: { namespace: 'dynamicAds.similar.model', model: model_8 },
model_9: { namespace: 'geologic-ads.model', model: model_9 },
model_10: { namespace: 'standard-ads.past-review-results.model', model: model_10 },
model_11: { namespace: 'standard-ads.task-moderation.model', model: model_11 },
model_12: { namespace: 'task-pools.task-pool-details.model', model: model_12 },
model_13: { namespace: 'task-pools.task-pool-rules.model', model: model_13 },
model_14: { namespace: 'task-user-groups.task-user-group-details.model', model: model_14 },
} as const
