const snakeToCamel = (str: string): string =>
  str.replace(/_+([a-zA-Z0-9])/g, (_, first) => first.toUpperCase());

export const objectSnakeToCamel = (obj: Record<string, any>): Record<string, any> => {
  const result = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const camelKey = snakeToCamel(key);
      result[camelKey] =
        typeof value === 'object' && value !== null ? objectSnakeToCamel(value) : value;
    }
  }
  return result;
};
