import { dynamicAdsModerate } from '@/pages/dynamicAds/service';
import { Reducer } from '@@/plugin-dva/connect';
import type { Effect } from 'umi';
import { DynamicAdsModeration, DynamicAdsModerationStatus } from '../data.d';

export interface DynamicAdsModerationStateType {
  current: DynamicAdsModeration;
}

export interface DynamicAdsModerationModelType {
  namespace: string;
  state: DynamicAdsModerationStateType;
  effects: {
    moderate: Effect;
    selectItems: Effect;
    selectStatus: Effect;
  };
  reducers: {
    saveModerationItems: Reducer<DynamicAdsModerationStateType>;
    saveModerationStatus: Reducer<DynamicAdsModerationStateType>;
    saveDefaultDynamicAdsModeration: Reducer<DynamicAdsModerationStateType>;
  };
}

const defaultDynamicAdsModeration: DynamicAdsModeration = {
  items: [],
  moderationStatus: DynamicAdsModerationStatus.UNPROCESSED.toString(),
  moderationReason: '',
};

const DynamicAdsItemsModel: DynamicAdsModerationModelType = {
  namespace: 'dynamicAdsItems',
  state: {
    current: defaultDynamicAdsModeration,
  },
  effects: {
    *moderate({ payload }, { call, put }) {
      yield call(dynamicAdsModerate, {
        ids: payload.ids,
        moderationStatus: payload.moderationStatus,
        moderationStatusSfd: payload.moderationStatusSfd,
        moderationReason: payload.moderationReason,
        moderationNote: payload.moderationNote,
      });
      yield put({
        type: 'saveDefaultDynamicAdsModeration',
        payload,
      });
    },
    *selectItems({ payload }, { put }) {
      yield put({
        type: 'saveModerationItems',
        payload,
      });
    },
    *selectStatus({ payload }, { put }) {
      yield put({
        type: 'saveModerationStatus',
        payload,
      });
    },
  },
  reducers: {
    saveModerationItems(state, action) {
      return {
        ...state,
        current: {
          ...state!!.current,
          items: action.payload,
        },
      };
    },
    saveModerationStatus(state, action) {
      console.log('payload', action.payload);
      return {
        ...state,
        current: {
          ...state!!.current,
          ...action.payload,
        },
      };
    },
    saveDefaultDynamicAdsModeration(state) {
      return {
        ...state,
        current: defaultDynamicAdsModeration,
      };
    },
  },
};

export default DynamicAdsItemsModel;
