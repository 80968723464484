import { getTaskPoolByName, updateTaskPool } from '@/pages/utils/service';
import type { Effect, Reducer } from '@@/plugin-dva/connect';
import { message } from 'antd';

export interface TaskPoolDetailsStateType {
  taskPoolModel: TaskPoolModel;
}

export interface TaskPoolDetailsModelType {
  namespace: 'taskPoolDetails';
  state: TaskPoolDetailsStateType;
  effects: {
    fetch: Effect;
    submitTaskPool: Effect;
  };
  reducers: {
    saveTaskPool: Reducer<TaskPoolDetailsStateType>;
    updateTaskPool: Reducer<TaskPoolDetailsStateType>;
  };
}

export interface TaskPoolModel {
  id: string;
  namespace: string;
  name: string;
  description: string;
  type: string;
  taskUserGroupName?: string;
  escalationPoolName: string;
}

const DEFAULT_TASK_POOL_MODEL: TaskPoolModel = {
  id: '-',
  name: '-',
  description: '-',
  type: '-',
  taskUserGroupName: '-',
  namespace: '-',
  escalationPoolName: '-',
};

const TaskPoolDetailsModel: TaskPoolDetailsModelType = {
  namespace: 'taskPoolDetails',

  state: {
    taskPoolModel: { ...DEFAULT_TASK_POOL_MODEL },
  },

  effects: {
    *fetch({ payload: { namespace: namespace, taskPoolName: taskPoolName } }, { call, put }) {
      try {
        const { data: taskPool } = yield call(getTaskPoolByName, namespace, taskPoolName);
        yield put({
          type: 'saveTaskPool',
          payload: {
            id: taskPool.id,
            namespace: taskPool.namespace,
            name: taskPool.name,
            description: taskPool.description,
            type: taskPool.type,
            taskUserGroupName: taskPool.taskUserGroup ? taskPool.taskUserGroup.name : null,
            escalationPoolName: taskPool.escalationPoolName,
          },
        });
      } catch (e) {
        message.error('Fail to fetch task pool: ' + e);
      }
    },
    *submitTaskPool({ payload }, { call, put }) {
      try {
        yield put({
          type: 'updateTaskPool',
          payload: payload.request,
        });
        yield call(updateTaskPool, payload.namespace, payload.taskPoolName, payload.request);
        message.info('Task pool updated successfully.');
      } catch (e) {
        message.error('Fail to update task pool: ' + e);
      }
    },
  },
  reducers: {
    saveTaskPool(state, action) {
      return {
        ...state,
        taskPoolModel: action.payload || {},
      };
    },
    updateTaskPool(state, action) {
      return {
        ...state,
        taskPoolModel: {
          ...state!!.taskPoolModel,
          ...action.payload,
        },
      };
    },
  },
};

export default TaskPoolDetailsModel;
