import { objectSnakeToCamel } from '@/models/decode';
import type { ItemDecoded, ItemGeoLogicAds, Task } from '@/models/task';
import { decodeItem } from '@/models/task';
import type { GeoLogicInfeedCreativeRequest, TaskGeoLogicAds } from './model';
import { isItemGeoLogicAds } from './model';

export const decodeItemGeoLogicAds = (
  item: ItemDecoded,
): ItemGeoLogicAds<GeoLogicInfeedCreativeRequest> => {
  if (!isItemGeoLogicAds(item)) {
    throw new Error('given item is not GeoLogicAds item');
  }
  const request = objectSnakeToCamel(item.payload);
  return {
    ...item,
    payload: request as GeoLogicInfeedCreativeRequest,
  };
};

export const decodeTaskGeoLogicAds = (task: Task): TaskGeoLogicAds | undefined => {
  const items = task.items?.map(decodeItem)?.map(decodeItemGeoLogicAds);
  if (items === undefined || items.length === 0 || items.length > 1) {
    // remove comment out before release
    // throw new Error('GeoLogicAds task should have one item')
    return undefined;
  }
  return {
    ...task,
    payload: null,
    item: items[0],
  };
};
