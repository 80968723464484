import type { ModerationStatus } from '@/models/enums';
import type { RejectReasons } from '@/models/standardAds/rejectReason';
import { generateReviewComment, rejectReasonCodeToValue } from '@/models/standardAds/rejectReason';
import type {
  CampaignCreativeCarouselRequest,
  CampaignCreativeImageRequest,
  CampaignCreativeRequest,
  CampaignCreativeVideoRequest,
  ElementsReviewedData,
  MediaId,
  RejectReasonCodes,
} from '@/models/standardAds/reviewRequest';
import {
  isCampaignCreativeCarouselRequest,
  isCampaignCreativeImageRequest,
  isCampaignCreativeVideoRequest,
} from '@/models/standardAds/reviewRequest';
import type { ItemStandardAds, ModerationHistory } from '@/models/task';
import { convertStringKeyMapToNumberKey } from '@/utils/utils';

export type ElementReviewStatus = 'NotReviewed' | 'Rejected' | 'Attention' | 'Escalated';

export type CreativeReviewStatus =
  | 'NotReviewed'
  | 'Approved'
  | 'Rejected'
  | 'Attention'
  | 'Escalated';

export type ElementType =
  | 'sponsor'
  | 'title'
  | 'body'
  | 'destination'
  | 'image'
  | 'video'
  | 'carouselTitle'
  | 'carouselImage';

export const ReviewCommentMaxLength = 1024;
export const InternalCommentMaxLength = 1024;
export const RejectReasonMaxCount = 10;

const notReviewedStatus: ElementReviewStatus = 'NotReviewed';

const nonUndefined = <T>(e: T): e is Exclude<typeof e, undefined> => e !== undefined;

const toCreativeReviewStatus = (moderationStatus: ModerationStatus): CreativeReviewStatus => {
  switch (moderationStatus) {
    case 'APPROVED':
    case 'AUTO_APPROVED':
      return 'Approved';
    case 'REJECTED':
    case 'AUTO_REJECTED':
      return 'Rejected';
    case 'ESCALATED':
      return 'Escalated';
    case 'UNPROCESSED':
      return 'NotReviewed';
    default:
      throw new Error('invalid moderation status');
  }
};

export class ElementModeration {
  readonly reviewStatus: ElementReviewStatus;
  readonly reviewComment: string;
  readonly internalComment: string;
  readonly rejectCodes: string[][];
  updateStatusTs: number = 0;
  updateReviewCommentTs: number = 0;
  updateInternalCommentTs: number = 0;
  constructor(
    reviewStatus: ElementReviewStatus,
    reviewComment: string,
    internalComment: string,
    rejectCodes: string[][],
    ...updatedTs: number[]
  ) {
    this.reviewStatus = reviewStatus;
    this.reviewComment = reviewComment;
    this.internalComment = internalComment;
    this.rejectCodes = rejectCodes;
    if (updatedTs.length > 0) this.updateStatusTs = updatedTs[0];
    if (updatedTs.length > 1) this.updateReviewCommentTs = updatedTs[1];
    if (updatedTs.length > 2) this.updateInternalCommentTs = updatedTs[2];
  }

  private static NotReviewed = ElementModeration.createWithReviewStatus('NotReviewed');

  static fromObject(obj: any): ElementModeration | undefined {
    if (obj === undefined) return undefined;
    return Object.assign(ElementModeration.createWithReviewStatus('NotReviewed'), obj);
  }

  static notReviewed(): ElementModeration {
    return ElementModeration.NotReviewed;
  }

  static createWithReviewStatus(reviewStatus: ElementReviewStatus): ElementModeration {
    return new ElementModeration(reviewStatus, '', '', []);
  }

  static copyWithCurrentTimestamp(e: ElementModeration): ElementModeration {
    const ts = Date.now();
    return new ElementModeration(
      e.reviewStatus,
      e.reviewComment,
      e.internalComment,
      e.rejectCodes,
      ts,
      ts,
      ts,
    );
  }

  isRejected(): boolean {
    return this.reviewStatus === 'Rejected';
  }

  isAttention(): boolean {
    return this.reviewStatus === 'Attention';
  }

  isEscalated(): boolean {
    return this.reviewStatus === 'Escalated';
  }

  isReviewed(): boolean {
    return this.isRejected() || this.isAttention() || this.isEscalated();
  }

  updateStatus(reviewStatus: ElementReviewStatus): ElementModeration {
    return new ElementModeration(
      reviewStatus,
      this.reviewComment,
      this.internalComment,
      this.rejectCodes,
    );
  }
}

const mergeReviewStatus = (moderations: ElementReviewStatus[]): ElementReviewStatus =>
  moderations.reduce((previous, current) => {
    if (previous === 'Rejected' || current === 'Rejected') return 'Rejected';
    if (current === 'Attention') return 'Attention';
    if (current === 'Escalated') return 'Escalated';
    return previous;
  }, 'NotReviewed' as ElementReviewStatus);

const mergeComment =
  (f: (t: ElementModeration) => string) =>
  (moderations: ElementModeration[]): string =>
    moderations.map(f).reduce((prev, comment) => {
      if (comment.length === 0) return prev;
      if (prev.length === 0) return comment;
      return `${prev}\n${comment}`;
    }, '');

export class ElementModerations<K extends MediaId | string> {
  private moderations: Map<K, ElementModeration>;

  constructor(moderations: Map<K, ElementModeration>) {
    this.moderations = moderations;
  }

  static create<K extends MediaId | string>(): ElementModerations<K> {
    return new ElementModerations(new Map());
  }

  set(key: K, moderation: ElementModeration): ElementModerations<K> {
    const newModerations = new Map(this.moderations);
    newModerations.set(key, moderation);
    return new ElementModerations(newModerations);
  }

  static fromObject<T extends MediaId | string>(
    map?: Map<T, any>,
  ): ElementModerations<T> | undefined {
    if (map === undefined) {
      return undefined;
    }
    const newModerations = new Map<T, ElementModeration>();
    for (const [key, value] of map) {
      newModerations.set(
        key as T,
        Object.assign(ElementModeration.createWithReviewStatus('NotReviewed'), value),
      );
    }
    return new ElementModerations(newModerations);
  }

  get(key: K): ElementModeration | undefined {
    return this.moderations.get(key);
  }

  getAll(keys: K[]): ElementModerations<K> {
    const moderations = new Map<K, ElementModeration>();
    keys.forEach((key) => {
      const moderation = this.get(key);
      if (nonUndefined(moderation)) {
        moderations.set(key, moderation);
      }
    });
    return new ElementModerations(moderations);
  }

  getModerations(): ElementModeration[] {
    return Array.from(this.moderations.values());
  }

  getModerationEntries(): [K, ElementModeration][] {
    return Array.from(this.moderations.entries());
  }

  countRejected(): number {
    return this.getModerations().filter((e) => e.isRejected()).length;
  }

  countAttention(): number {
    return this.getModerations().filter((e) => e.isAttention()).length;
  }

  getRejectedKeys(): K[] {
    return Array.from(this.moderations.entries())
      .filter(([, m]) => m.isRejected())
      .map(([k]) => k);
  }

  hasRejected(): boolean {
    return this.getModerations().some((m) => m.isRejected());
  }

  hasReviewed(): boolean {
    return this.getModerations().some((m) => m.isReviewed());
  }

  hasReviewedComment(): boolean {
    return this.getModerations().some((m) => !!m.reviewComment);
  }

  hasReviewedCode(): boolean {
    return this.getModerations().some((m) => !!m.rejectCodes && m.rejectCodes.length > 0);
  }

  hasReviewedInternalComment(): boolean {
    return this.getModerations().some((m) => !!m.internalComment);
  }
}

export class ItemModerationHistory {
  readonly moderator: string | undefined;
  readonly moderatedAt: number | undefined;
  readonly moderationStatus: string;
  readonly moderationReason: string | undefined;
  readonly moderationNote: string | undefined;
  readonly taskId: string | undefined;
  readonly reviewWorkflow: string | undefined;

  constructor(
    moderator: string | undefined,
    moderatedAt: number | undefined,
    moderationStatus: string,
    moderationReason: string | undefined,
    moderationNote: string | undefined,
    taskId: string | undefined,
    reviewWorkflow: string | undefined,
  ) {
    this.moderator = moderator;
    this.moderatedAt = moderatedAt;
    this.moderationStatus = moderationStatus;
    this.moderationReason = moderationReason;
    this.moderationNote = moderationNote;
    this.taskId = taskId;
    this.reviewWorkflow = reviewWorkflow;
  }

  static fromObject(itemModerationHistories: ModerationHistory[]): ItemModerationHistory[] {
    const newModerationHistories: ItemModerationHistory[] = [];
    itemModerationHistories.forEach((mh) =>
      newModerationHistories.push(
        new ItemModerationHistory(
          mh.moderator,
          mh.moderatedAt,
          mh.moderationStatus,
          mh.moderationReason,
          mh.moderationNote,
          mh.taskId ?? '',
          mh.reviewWorkflow,
        ),
      ),
    );
    return newModerationHistories;
  }
}

export class CreativeModeration {
  readonly reviewStatus: CreativeReviewStatus;
  readonly reviewComment: string | undefined; // contains both creative and element level comments
  readonly internalComment: string | undefined;
  readonly creativeRejectCodes: string[][];
  readonly rejectCodes: string[][];
  readonly elementModerationPair: ElementModerationPair; // contains element level moderation
  readonly creativeComment: string | undefined; // contains creative level comments only
  readonly taskId: string | undefined;
  updateStatusTs: number = 0;
  updateReviewCommentTs: number = 0;
  updateInternalCommentTs: number = 0;

  constructor(
    reviewStatus: CreativeReviewStatus,
    reviewComment: string | undefined,
    internalComment: string | undefined,
    creativeRejectCodes: string[][],
    rejectCodes: string[][],
    elementModerationPair: ElementModerationPair,
    creativeComment: string | undefined,
    taskId: string | undefined,
    ...updatedTs: number[]
  ) {
    this.reviewStatus = reviewStatus;
    this.reviewComment = reviewComment;
    this.internalComment = internalComment;
    this.creativeRejectCodes = creativeRejectCodes;
    this.rejectCodes = rejectCodes;
    this.elementModerationPair = { ...elementModerationPair };
    this.creativeComment = creativeComment;
    this.taskId = taskId;
    if (updatedTs.length > 0) this.updateStatusTs = updatedTs[0];
    if (updatedTs.length > 1) this.updateReviewCommentTs = updatedTs[1];
    if (updatedTs.length > 2) this.updateInternalCommentTs = updatedTs[2];
  }

  private static NotReviewed = new CreativeModeration('NotReviewed', '', '', [], [], {}, '', '');

  static notReviewed(): CreativeModeration {
    return CreativeModeration.NotReviewed;
  }

  static fromModerationHistory(
    mh: ModerationHistory,
    rejectReasons: RejectReasons,
  ): CreativeModeration {
    const elementsReviewedData: ElementsReviewedData = !!mh.extra ? JSON.parse(mh.extra) : {};
    const creativeRejectCodes = elementsReviewedData.rejectReasonCodes?.creative
      ? rejectReasonCodeToValue(
          rejectReasons,
          elementsReviewedData.rejectReasonCodes?.creative ?? [],
        )
      : [];
    const rejectCodes = elementsReviewedData.rejectReasonCodes
      ? rejectReasonCodeToValue(
          rejectReasons,
          CreativeModeration.getAllRejectCodes(elementsReviewedData.rejectReasonCodes) ?? [],
        )
      : [];
    return new CreativeModeration(
      toCreativeReviewStatus(mh.moderationStatus),
      mh.moderationReason,
      mh.moderationNote,
      creativeRejectCodes,
      rejectCodes,
      {},
      mh.moderationReason,
      mh.taskId ?? '',
    );
  }

  static getAllRejectCodes(rejectReasonCodes: RejectReasonCodes): string[] {
    let rejectCodes: string[] = [];
    rejectCodes = rejectCodes
      .concat(rejectReasonCodes?.sponsor ?? [])
      .concat(rejectReasonCodes?.title ?? [])
      .concat(rejectReasonCodes?.body ?? [])
      .concat(rejectReasonCodes?.destination ?? []);
    if (rejectReasonCodes?.image) {
      Object.values(rejectReasonCodes.image).forEach(
        (value) => (rejectCodes = rejectCodes.concat(value)),
      );
    }
    if (rejectReasonCodes?.video) {
      Object.values(rejectReasonCodes.video).forEach(
        (value) => (rejectCodes = rejectCodes.concat(value)),
      );
    }
    if (rejectReasonCodes?.carouselTitle) {
      Object.values(rejectReasonCodes.carouselTitle).forEach(
        (value) => (rejectCodes = rejectCodes.concat(value)),
      );
    }
    rejectCodes = rejectCodes.concat(rejectReasonCodes?.creative ?? []);

    return rejectCodes;
  }

  static fromObjectMap(obj: any): CreativeModeration {
    const elementModerationPair = obj.elementModerationPair;
    return new CreativeModeration(
      obj.reviewStatus,
      obj.reviewComment,
      obj.internalComment,
      obj.creativeRejectCodes,
      obj.rejectCodes,
      {
        sponsorModeration: ElementModeration.fromObject(elementModerationPair.sponsorModeration),
        titleModeration: ElementModeration.fromObject(elementModerationPair.titleModeration),
        bodyModeration: ElementModeration.fromObject(elementModerationPair.bodyModeration),
        destinationModeration: ElementModeration.fromObject(
          elementModerationPair.destinationModeration,
        ),
        imageModerations: ElementModerations.fromObject<MediaId>(
          convertStringKeyMapToNumberKey(elementModerationPair.imageModerations?.moderations),
        ),
        videoModerations: ElementModerations.fromObject<MediaId>(
          convertStringKeyMapToNumberKey(elementModerationPair.videoModerations?.moderations),
        ),
        carouselImageModerations: ElementModerations.fromObject<MediaId>(
          convertStringKeyMapToNumberKey(
            elementModerationPair.carouselImageModerations?.moderations,
          ),
        ),
        carouselTitleModerations: ElementModerations.fromObject<string>(
          elementModerationPair.carouselTitleModerations?.moderations,
        ),
      },
      obj.creativeComment,
      obj.taskId,
    );
  }

  static create(
    elementModerationPair: ElementModerationPair,
    creativeModeration: CreativeModeration,
    rejectReasons: RejectReasons,
  ): CreativeModeration {
    const {
      sponsorModeration,
      titleModeration,
      bodyModeration,
      destinationModeration,
      imageModerations,
      videoModerations,
      carouselTitleModerations,
      carouselImageModerations,
    } = elementModerationPair;

    const moderations = [
      sponsorModeration,
      titleModeration,
      bodyModeration,
      destinationModeration,
      ...(imageModerations?.getModerations() ?? []),
      ...(videoModerations?.getModerations() ?? []),
      ...(carouselImageModerations?.getModerations() ?? []),
      ...(carouselTitleModerations?.getModerations() ?? []),
    ].filter(nonUndefined);

    const reviewStatus = moderations.some(
      (m) => m.updateStatusTs > creativeModeration.updateStatusTs,
    )
      ? mergeReviewStatus(moderations.map((m) => m.reviewStatus))
      : creativeModeration.reviewStatus;
    const internalComment = moderations.some(
      (m) => m.updateInternalCommentTs > creativeModeration.updateInternalCommentTs,
    )
      ? mergeComment((m) => m.internalComment)(moderations)
      : creativeModeration.internalComment;
    let rejectCodes: string[][] = [];
    if (
      moderations.some((m) => m.updateReviewCommentTs > creativeModeration.updateReviewCommentTs)
    ) {
      if (sponsorModeration?.rejectCodes)
        rejectCodes = rejectCodes.concat(sponsorModeration.rejectCodes);
      if (titleModeration?.rejectCodes)
        rejectCodes = rejectCodes.concat(titleModeration?.rejectCodes);
      if (bodyModeration?.rejectCodes)
        rejectCodes = rejectCodes.concat(bodyModeration?.rejectCodes);
      if (destinationModeration?.rejectCodes)
        rejectCodes = rejectCodes.concat(destinationModeration?.rejectCodes);
      imageModerations
        ?.getModerations()
        .forEach((mode) => (rejectCodes = rejectCodes.concat(mode.rejectCodes)));
      videoModerations
        ?.getModerations()
        .forEach((mode) => (rejectCodes = rejectCodes.concat(mode.rejectCodes)));
      carouselTitleModerations
        ?.getModerations()
        .forEach((mode) => (rejectCodes = rejectCodes.concat(mode.rejectCodes)));
      carouselImageModerations
        ?.getModerations()
        .forEach((mode) => (rejectCodes = rejectCodes.concat(mode.rejectCodes)));
      rejectCodes = rejectCodes.concat(creativeModeration.creativeRejectCodes);
    } else {
      rejectCodes = creativeModeration.rejectCodes;
    }
    const ts = Date.now();
    return new CreativeModeration(
      reviewStatus,
      generateReviewComment(rejectReasons, rejectCodes),
      internalComment,
      creativeModeration.creativeRejectCodes,
      rejectCodes,
      elementModerationPair,
      generateReviewComment(rejectReasons, creativeModeration.creativeRejectCodes),
      creativeModeration.taskId,
      ts,
      ts,
      ts,
    );
  }

  isApproved(): boolean {
    return this.reviewStatus === 'Approved';
  }

  isRejected(): boolean {
    return this.reviewStatus === 'Rejected';
  }

  isEscalated(): boolean {
    return this.reviewStatus === 'Escalated';
  }

  isValid(): boolean {
    const validInternalComment = (this.internalComment?.length ?? 0) <= InternalCommentMaxLength;
    const validReviewComment =
      (this.reviewComment?.length ?? 0) <= ReviewCommentMaxLength &&
      this.rejectCodes.length <= RejectReasonMaxCount;
    switch (this.reviewStatus) {
      case 'Approved':
        return validReviewComment && validInternalComment && this.rejectedElements().size === 0;
      case 'Rejected':
        return validReviewComment && validInternalComment && this.rejectCodes.length > 0;
      case 'Escalated':
        return validInternalComment && (this.internalComment?.length ?? 0) > 0;
      case 'Attention':
      case 'NotReviewed':
        return false;
    }
  }

  sponsorRejected(): boolean {
    return this.elementModerationPair.sponsorModeration?.isRejected() ?? false;
  }

  titleRejected(): boolean {
    return this.elementModerationPair.titleModeration?.isRejected() ?? false;
  }

  bodyRejected(): boolean {
    return this.elementModerationPair.bodyModeration?.isRejected() ?? false;
  }

  destinationRejected(): boolean {
    return this.elementModerationPair.destinationModeration?.isRejected() ?? false;
  }

  rejectedElements(): Set<ElementType> {
    const rejectedElements: ElementType[] = [];

    if (this.sponsorRejected()) {
      rejectedElements.push('sponsor');
    }
    if (this.titleRejected()) {
      rejectedElements.push('title');
    }
    if (this.bodyRejected()) {
      rejectedElements.push('body');
    }
    if (this.destinationRejected()) {
      rejectedElements.push('destination');
    }
    if (this.elementModerationPair.imageModerations?.hasRejected()) {
      rejectedElements.push('image');
    }
    if (this.elementModerationPair.videoModerations?.hasRejected()) {
      rejectedElements.push('video');
    }
    if (this.elementModerationPair.carouselTitleModerations?.hasRejected()) {
      rejectedElements.push('carouselTitle');
    }
    if (this.elementModerationPair.carouselImageModerations?.hasRejected()) {
      rejectedElements.push('carouselImage');
    }

    return new Set(rejectedElements);
  }

  rejectedImageIds(): MediaId[] {
    return this.elementModerationPair.imageModerations?.getRejectedKeys() ?? [];
  }

  rejectedVideoIds(): MediaId[] {
    return this.elementModerationPair.videoModerations?.getRejectedKeys() ?? [];
  }

  rejectedCarouselImageIds(): MediaId[] {
    return this.elementModerationPair.carouselImageModerations?.getRejectedKeys() ?? [];
  }

  rejectedCarouselTitles(): string[] {
    return this.elementModerationPair.carouselTitleModerations?.getRejectedKeys() ?? [];
  }

  private copy({
    reviewStatus,
    reviewComment,
    internalComment,
    creativeRejectCodes,
    rejectCodes,
    elementModerationPair,
    creativeComment,
    taskId,
    updateStatusTs,
    updateReviewCommentTs,
    updateInternalCommentTs,
  }: {
    reviewStatus?: CreativeReviewStatus;
    reviewComment?: string;
    internalComment?: string;
    creativeRejectCodes?: string[][];
    rejectCodes?: string[][];
    elementModerationPair?: ElementModerationPair;
    creativeComment?: string;
    taskId?: string;
    updateStatusTs?: number;
    updateReviewCommentTs?: number;
    updateInternalCommentTs?: number;
  }) {
    return new CreativeModeration(
      reviewStatus ?? this.reviewStatus,
      reviewComment ?? this.reviewComment,
      internalComment ?? this.internalComment,
      creativeRejectCodes ?? this.creativeRejectCodes,
      rejectCodes ?? this.rejectCodes,
      elementModerationPair ?? this.elementModerationPair,
      creativeComment ?? this.creativeComment,
      taskId ?? this.taskId,
      updateStatusTs ?? this.updateStatusTs,
      updateReviewCommentTs ?? this.updateReviewCommentTs,
      updateInternalCommentTs ?? this.updateInternalCommentTs,
    );
  }

  updateElementModerationPair(elementModerationPair?: ElementModerationPair): CreativeModeration {
    return this.copy({ elementModerationPair });
  }

  updateSponsor(moderation: ElementModeration): CreativeModeration {
    return this.copy({
      elementModerationPair: {
        ...this.elementModerationPair,
        sponsorModeration: moderation,
      },
    });
  }

  updateTitle(moderation: ElementModeration): CreativeModeration {
    return this.copy({
      elementModerationPair: {
        ...this.elementModerationPair,
        titleModeration: moderation,
      },
    });
  }

  updateBody(moderation: ElementModeration): CreativeModeration {
    return this.copy({
      elementModerationPair: {
        ...this.elementModerationPair,
        bodyModeration: moderation,
      },
    });
  }

  updateDestination(moderation: ElementModeration): CreativeModeration {
    return this.copy({
      elementModerationPair: {
        ...this.elementModerationPair,
        destinationModeration: moderation,
      },
    });
  }

  updateImage(imageId: MediaId, moderation: ElementModeration): CreativeModeration {
    const currentImageModerations =
      this.elementModerationPair.imageModerations ?? ElementModerations.create();
    const imageModerations = currentImageModerations.set(imageId, moderation);
    return this.copy({
      elementModerationPair: { ...this.elementModerationPair, imageModerations },
    });
  }

  updateVideo(videoId: MediaId, moderation: ElementModeration): CreativeModeration {
    const currentVideoModerations =
      this.elementModerationPair.videoModerations ?? ElementModerations.create();
    const videoModerations = currentVideoModerations.set(videoId, moderation);
    return this.copy({
      elementModerationPair: { ...this.elementModerationPair, videoModerations },
    });
  }

  updateCarouselImage(imageId: MediaId, moderation: ElementModeration): CreativeModeration {
    const currentCarouselImageModerations =
      this.elementModerationPair.carouselImageModerations ?? ElementModerations.create();
    const carouselImageModerations = currentCarouselImageModerations.set(imageId, moderation);
    return this.copy({
      elementModerationPair: { ...this.elementModerationPair, carouselImageModerations },
    });
  }

  updateCarouselTitle(content: string, moderation: ElementModeration): CreativeModeration {
    const currentCarouselTitleModerations =
      this.elementModerationPair.carouselTitleModerations ?? ElementModerations.create();
    const carouselTitleModerations = currentCarouselTitleModerations.set(content, moderation);
    return this.copy({
      elementModerationPair: { ...this.elementModerationPair, carouselTitleModerations },
    });
  }

  updateReviewState(reviewStatus: CreativeReviewStatus): CreativeModeration {
    return this.copy({ reviewStatus: reviewStatus });
  }

  updateModeration(moderation: CreativeModeration): CreativeModeration {
    return this.copy({
      reviewStatus: moderation.reviewStatus,
      reviewComment: moderation.reviewComment,
      internalComment: moderation.internalComment,
      creativeRejectCodes: moderation.creativeRejectCodes,
      rejectCodes: moderation.rejectCodes,
      creativeComment: moderation.creativeComment,
      updateStatusTs: moderation.updateStatusTs,
      updateReviewCommentTs: moderation.updateReviewCommentTs,
      updateInternalCommentTs: moderation.updateInternalCommentTs,
    });
  }

  updateWithCurrentTimestamp(): CreativeModeration {
    const ts = Date.now();
    return this.copy({
      updateStatusTs: ts,
      updateReviewCommentTs: ts,
      updateInternalCommentTs: ts,
    });
  }
}

export class ItemModerationHistories {
  private readonly moderationHistories: Map<number, ItemModerationHistory[]>;

  constructor(moderationHistories: Map<number, ItemModerationHistory[]>) {
    this.moderationHistories = moderationHistories;
  }

  static create(): ItemModerationHistories {
    return new ItemModerationHistories(new Map());
  }

  static fromItems(items: ItemStandardAds<CampaignCreativeRequest>[]): ItemModerationHistories {
    return items.reduce(
      (prev, item) =>
        !!item.moderationHistory
          ? prev.copyAndSet(
              item.payload.key,
              ItemModerationHistory.fromObject(item.moderationHistory),
            )
          : prev,
      ItemModerationHistories.create(),
    );
  }

  copyAndSet(ccId: number, moderationHistory: ItemModerationHistory[]): ItemModerationHistories {
    const newModerationHistories = new Map(this.moderationHistories);
    newModerationHistories.set(ccId, moderationHistory);
    return new ItemModerationHistories(newModerationHistories);
  }

  getModerationHistory(key: number): ItemModerationHistory[] {
    return this.moderationHistories?.get(key) ?? [];
  }
}

export class CreativeModerations {
  private moderations: Map<number, CreativeModeration>;

  constructor(moderations: Map<number, CreativeModeration>) {
    this.moderations = moderations;
  }

  static create(): CreativeModerations {
    return new CreativeModerations(new Map());
  }

  private static hasOnlyLatestModerationHistory(
    item: ItemStandardAds<CampaignCreativeRequest>,
  ): boolean {
    return !!item.latestModerationHistory && 0 === (item.moderationHistory?.length ?? 0);
  }

  private static hasModerationHistoryForTask(
    item: ItemStandardAds<CampaignCreativeRequest>,
    taskId: string,
  ): boolean {
    return (
      item.moderationHistory !== undefined &&
      item.moderationHistory.find((mh) => mh.taskId === taskId) !== undefined
    );
  }

  static fromItems(
    items: ItemStandardAds<CampaignCreativeRequest>[],
    taskId: string,
    moderations: Moderations,
    rejectReasons: RejectReasons,
  ): CreativeModerations {
    return items
      .filter((item) => !item.isDeleted || !!item.latestModerationHistory)
      .reduce((prev, item) => {
        if (CreativeModerations.hasOnlyLatestModerationHistory(item)) {
          return prev.copyAndSet(
            item.payload.key,
            CreativeModeration.fromModerationHistory(
              item.latestModerationHistory!!,
              rejectReasons,
            ).updateElementModerationPair(moderations.findElementModerationPair(item.payload)),
          );
        } else if (CreativeModerations.hasModerationHistoryForTask(item, taskId)) {
          const moderationHistoryOrderedByModerationTimeDesc = item.moderationHistory!!.toSorted(
            (a, b) => (b.moderatedAt ?? 0) - (a.moderatedAt ?? 0),
          );
          return prev.copyAndSet(
            item.payload.key,
            CreativeModeration.fromModerationHistory(
              moderationHistoryOrderedByModerationTimeDesc.find((mh) => mh.taskId === taskId)!!,
              rejectReasons,
            ).updateElementModerationPair(moderations.findElementModerationPair(item.payload)),
          );
        } else {
          return prev;
        }
      }, CreativeModerations.create());
  }

  static fromObjectMap(map: Map<string, any>): CreativeModerations {
    const moderations = new Map<number, CreativeModeration>();
    for (const [key, value] of map) {
      moderations.set(Number(key), CreativeModeration.fromObjectMap(value));
    }
    return new CreativeModerations(moderations);
  }

  copyAndSet(key: number, moderation: CreativeModeration): CreativeModerations {
    const newModerations = new Map(this.moderations);
    newModerations.set(key, moderation);
    return new CreativeModerations(newModerations);
  }

  copyAndUpdate(key: number, moderation: CreativeModeration): CreativeModerations {
    const newModerations = new Map(this.moderations);
    if (newModerations.has(key)) {
      const originModeration = newModerations.get(key);
      const newModeration = originModeration?.updateModeration(moderation);
      newModerations.set(key, !!newModeration ? newModeration : moderation);
    } else {
      newModerations.set(key, moderation);
    }
    return new CreativeModerations(newModerations);
  }

  get(key: number): CreativeModeration | undefined {
    return this.moderations.get(key);
  }

  hasAllOf(keys: number[]): boolean {
    return keys.every((id) => this.moderations.has(id));
  }

  allValid(): boolean {
    const moderations = Array.from(this.moderations.values());
    return moderations.length > 0 && moderations.every((m) => m.isValid());
  }

  hasEscalated(): boolean {
    const moderations = Array.from(this.moderations.values());
    return moderations.length > 0 && moderations.some((m) => m.isEscalated());
  }

  entries(): [number, CreativeModeration][] {
    return Array.from(this.moderations.entries());
  }
}

export interface ElementModerationPair {
  sponsorModeration?: ElementModeration;
  titleModeration?: ElementModeration;
  bodyModeration?: ElementModeration;
  destinationModeration?: ElementModeration;
  imageModerations?: ElementModerations<MediaId>;
  videoModerations?: ElementModerations<MediaId>;
  carouselImageModerations?: ElementModerations<MediaId>;
  carouselTitleModerations?: ElementModerations<string>;
}

export class Moderations {
  readonly sponsorModerations: ElementModerations<string>;
  readonly titleModerations: ElementModerations<string>;
  readonly bodyModerations: ElementModerations<string>;
  readonly destinationModerations: ElementModerations<string>;
  readonly imageModerations: ElementModerations<MediaId>;
  readonly videoModerations: ElementModerations<MediaId>;
  readonly carouselTitleModerations: ElementModerations<string>;

  constructor(
    sponsorModerations: ElementModerations<string>,
    titleModerations: ElementModerations<string>,
    bodyModerations: ElementModerations<string>,
    destinationModerations: ElementModerations<string>,
    imageModerations: ElementModerations<MediaId>,
    videoModerations: ElementModerations<MediaId>,
    carouselTitleModerations: ElementModerations<string>,
  ) {
    this.sponsorModerations = sponsorModerations;
    this.titleModerations = titleModerations;
    this.bodyModerations = bodyModerations;
    this.destinationModerations = destinationModerations;
    this.imageModerations = imageModerations;
    this.videoModerations = videoModerations;
    this.carouselTitleModerations = carouselTitleModerations;
  }

  static create() {
    return new Moderations(
      ElementModerations.create(),
      ElementModerations.create(),
      ElementModerations.create(),
      ElementModerations.create(),
      ElementModerations.create(),
      ElementModerations.create(),
      ElementModerations.create(),
    );
  }

  static fromObjectMap(obj: any): Moderations {
    return new Moderations(
      ElementModerations.fromObject<string>(obj.sponsorModerations.moderations) ??
        ElementModerations.create(),
      ElementModerations.fromObject<string>(obj.titleModerations.moderations) ??
        ElementModerations.create(),
      ElementModerations.fromObject<string>(obj.bodyModerations.moderations) ??
        ElementModerations.create(),
      ElementModerations.fromObject<string>(obj.destinationModerations.moderations) ??
        ElementModerations.create(),
      ElementModerations.fromObject<MediaId>(
        convertStringKeyMapToNumberKey(obj.imageModerations.moderations),
      ) ?? ElementModerations.create(),
      ElementModerations.fromObject<MediaId>(
        convertStringKeyMapToNumberKey(obj.videoModerations.moderations),
      ) ?? ElementModerations.create(),
      ElementModerations.fromObject<string>(obj.carouselTitleModerations.moderations) ??
        ElementModerations.create(),
    );
  }

  static fromItems(
    items: ItemStandardAds<CampaignCreativeRequest>[],
    rejectReasons: RejectReasons,
    taskId: string | undefined,
  ): Moderations {
    return items.reduce((prev, item) => {
      const campaignCreativeRequest: CampaignCreativeRequest = item.payload;
      const extra =
        taskId !== undefined
          ? item.moderationHistory?.find((mh) => mh.taskId === taskId)?.extra
          : item.latestModerationHistory?.extra;
      const elementsReviewedData: ElementsReviewedData = !!extra ? JSON.parse(extra) : {};
      if (Moderations.hasElementModeration(elementsReviewedData)) {
        const fs = [
          Moderations.dedupAndSetSponsorModerations,
          Moderations.dedupAndSetTitleModerations,
          Moderations.dedupAndSetBodyModerations,
          Moderations.dedupAndSetDestinationModerations,
          Moderations.dedupAndSetMediaModerations,
        ];
        return fs.reduce((pre, f) => {
          return f(pre, campaignCreativeRequest, elementsReviewedData, rejectReasons);
        }, prev);
      }
      return prev;
    }, Moderations.create());
  }

  static hasElementModeration(elementsReviewedData: ElementsReviewedData): boolean {
    return (
      !!elementsReviewedData.elementsReviewStatus ||
      !!elementsReviewedData.elementsReviewComment ||
      !!elementsReviewedData.elementsReviewInternalComment ||
      !!elementsReviewedData.rejectReasonCodes
    );
  }

  static hasSponsorElementModeration(elementsReviewedData: ElementsReviewedData): boolean {
    return (
      !!elementsReviewedData.elementsReviewStatus?.sponsor ||
      !!elementsReviewedData.elementsReviewComment?.sponsor ||
      !!elementsReviewedData.elementsReviewInternalComment?.sponsor ||
      !!elementsReviewedData.rejectReasonCodes?.sponsor
    );
  }

  static hasTitleElementModeration(elementsReviewedData: ElementsReviewedData): boolean {
    return (
      !!elementsReviewedData.elementsReviewStatus?.title ||
      !!elementsReviewedData.elementsReviewComment?.title ||
      !!elementsReviewedData.elementsReviewInternalComment?.title ||
      !!elementsReviewedData.rejectReasonCodes?.title
    );
  }

  static hasBodyElementModeration(elementsReviewedData: ElementsReviewedData): boolean {
    return (
      !!elementsReviewedData.elementsReviewStatus?.body ||
      !!elementsReviewedData.elementsReviewComment?.body ||
      !!elementsReviewedData.elementsReviewInternalComment?.body ||
      !!elementsReviewedData.rejectReasonCodes?.body
    );
  }

  static hasDestinationElementModeration(elementsReviewedData: ElementsReviewedData): boolean {
    return (
      !!elementsReviewedData.elementsReviewStatus?.destination ||
      !!elementsReviewedData.elementsReviewComment?.destination ||
      !!elementsReviewedData.elementsReviewInternalComment?.destination ||
      !!elementsReviewedData.rejectReasonCodes?.destination
    );
  }

  static hasImageElementModeration(elementsReviewedData: ElementsReviewedData): boolean {
    return (
      !!elementsReviewedData.elementsReviewStatus?.image ||
      !!elementsReviewedData.elementsReviewComment?.image ||
      !!elementsReviewedData.elementsReviewInternalComment?.image ||
      !!elementsReviewedData.rejectReasonCodes?.image
    );
  }

  static hasVideoElementModeration(elementsReviewedData: ElementsReviewedData): boolean {
    return (
      !!elementsReviewedData.elementsReviewStatus?.video ||
      !!elementsReviewedData.elementsReviewComment?.video ||
      !!elementsReviewedData.elementsReviewInternalComment?.video ||
      !!elementsReviewedData.rejectReasonCodes?.video
    );
  }

  static hasCarouselTitleElementModeration(elementsReviewedData: ElementsReviewedData): boolean {
    return (
      !!elementsReviewedData.elementsReviewStatus?.carouselTitle ||
      !!elementsReviewedData.elementsReviewComment?.carouselTitle ||
      !!elementsReviewedData.elementsReviewInternalComment?.carouselTitle ||
      !!elementsReviewedData.rejectReasonCodes?.carouselTitle
    );
  }

  static dedupAndSetSponsorModerations(
    prev: Moderations,
    campaignCreativeRequest: CampaignCreativeRequest,
    elementsReviewedData: ElementsReviewedData,
    rejectReasons: RejectReasons,
  ): Moderations {
    if (
      Moderations.hasSponsorElementModeration(elementsReviewedData) &&
      !prev.sponsorModerations.get(campaignCreativeRequest.sponsor)
    ) {
      const sponsorElementModeration = new ElementModeration(
        elementsReviewedData.elementsReviewStatus?.sponsor ?? notReviewedStatus,
        elementsReviewedData.elementsReviewComment?.sponsor ?? '',
        elementsReviewedData.elementsReviewInternalComment?.sponsor ?? '',
        elementsReviewedData.rejectReasonCodes?.sponsor
          ? rejectReasonCodeToValue(rejectReasons, elementsReviewedData.rejectReasonCodes?.sponsor)
          : [],
      );
      const sponsorModerations = prev.sponsorModerations.set(
        campaignCreativeRequest.sponsor,
        sponsorElementModeration,
      );
      return prev.copy({ sponsorModerations });
    }
    return prev;
  }

  static dedupAndSetTitleModerations(
    prev: Moderations,
    campaignCreativeRequest: CampaignCreativeRequest,
    elementsReviewedData: ElementsReviewedData,
    rejectReasons: RejectReasons,
  ): Moderations {
    if (
      Moderations.hasTitleElementModeration(elementsReviewedData) &&
      !prev.titleModerations.get(campaignCreativeRequest.title)
    ) {
      const titleElementModeration = new ElementModeration(
        elementsReviewedData.elementsReviewStatus?.title ?? notReviewedStatus,
        elementsReviewedData.elementsReviewComment?.title ?? '',
        elementsReviewedData.elementsReviewInternalComment?.title ?? '',
        elementsReviewedData.rejectReasonCodes?.title
          ? rejectReasonCodeToValue(rejectReasons, elementsReviewedData.rejectReasonCodes?.title)
          : [],
      );
      const titleModerations = prev.titleModerations.set(
        campaignCreativeRequest.title,
        titleElementModeration,
      );
      return prev.copy({ titleModerations });
    }
    return prev;
  }

  static dedupAndSetBodyModerations(
    prev: Moderations,
    campaignCreativeRequest: CampaignCreativeRequest,
    elementsReviewedData: ElementsReviewedData,
    rejectReasons: RejectReasons,
  ): Moderations {
    if (
      Moderations.hasBodyElementModeration(elementsReviewedData) &&
      !prev.bodyModerations.get(campaignCreativeRequest.body)
    ) {
      const bodyElementModeration = new ElementModeration(
        elementsReviewedData.elementsReviewStatus?.body ?? notReviewedStatus,
        elementsReviewedData.elementsReviewComment?.body ?? '',
        elementsReviewedData.elementsReviewInternalComment?.body ?? '',
        elementsReviewedData.rejectReasonCodes?.body
          ? rejectReasonCodeToValue(rejectReasons, elementsReviewedData.rejectReasonCodes?.body)
          : [],
      );
      const bodyModerations = prev.bodyModerations.set(
        campaignCreativeRequest.body,
        bodyElementModeration,
      );
      return prev.copy({ bodyModerations });
    }
    return prev;
  }

  static dedupAndSetDestinationModerations(
    prev: Moderations,
    campaignCreativeRequest: CampaignCreativeRequest,
    elementsReviewedData: ElementsReviewedData,
    rejectReasons: RejectReasons,
  ): Moderations {
    if (
      Moderations.hasDestinationElementModeration(elementsReviewedData) &&
      !prev.destinationModerations.get(campaignCreativeRequest.destination)
    ) {
      const destinationElementModeration = new ElementModeration(
        elementsReviewedData.elementsReviewStatus?.destination ?? notReviewedStatus,
        elementsReviewedData.elementsReviewComment?.destination ?? '',
        elementsReviewedData.elementsReviewInternalComment?.destination ?? '',
        elementsReviewedData.rejectReasonCodes?.destination
          ? rejectReasonCodeToValue(
              rejectReasons,
              elementsReviewedData.rejectReasonCodes?.destination,
            )
          : [],
      );
      const destinationModerations = prev.destinationModerations.set(
        campaignCreativeRequest.destination,
        destinationElementModeration,
      );
      return prev.copy({ destinationModerations });
    }
    return prev;
  }

  static dedupAndSetMediaModerations(
    prev: Moderations,
    campaignCreativeRequest: CampaignCreativeRequest,
    elementsReviewedData: ElementsReviewedData,
    rejectReasons: RejectReasons,
  ): Moderations {
    if (isCampaignCreativeImageRequest(campaignCreativeRequest)) {
      return Moderations.dedupAndSetImageModerations(
        prev,
        campaignCreativeRequest,
        elementsReviewedData,
        rejectReasons,
      );
    } else if (isCampaignCreativeVideoRequest(campaignCreativeRequest)) {
      return Moderations.dedupAndSetVideoModerations(
        prev,
        campaignCreativeRequest,
        elementsReviewedData,
        rejectReasons,
      );
    } else if (isCampaignCreativeCarouselRequest(campaignCreativeRequest)) {
      return Moderations.dedupAndSetCarouselTitleModerations(
        Moderations.dedupAndSetCarouselImageModerations(
          prev,
          campaignCreativeRequest,
          elementsReviewedData,
          rejectReasons,
        ),
        campaignCreativeRequest,
        elementsReviewedData,
        rejectReasons,
      );
    }
    return prev;
  }

  static dedupAndSetImageModerations(
    prev: Moderations,
    campaignCreativeImageRequest: CampaignCreativeImageRequest,
    elementsReviewedData: ElementsReviewedData,
    rejectReasons: RejectReasons,
  ): Moderations {
    if (Moderations.hasImageElementModeration(elementsReviewedData)) {
      return campaignCreativeImageRequest.assets.reduce((pre, asset) => {
        if (!pre.imageModerations.get(asset.imageId)) {
          const reviewStatus = elementsReviewedData.elementsReviewStatus?.image?.[asset.imageId];
          const reviewComment = elementsReviewedData.elementsReviewComment?.image?.[asset.imageId];
          const reviewInternalComment =
            elementsReviewedData.elementsReviewInternalComment?.image?.[asset.imageId];
          const rejectCodes = elementsReviewedData.rejectReasonCodes?.image?.[asset.imageId];
          const imageModerations = pre.imageModerations.set(
            asset.imageId,
            new ElementModeration(
              reviewStatus ?? notReviewedStatus,
              reviewComment ?? '',
              reviewInternalComment ?? '',
              rejectReasonCodeToValue(rejectReasons, rejectCodes),
            ),
          );
          return pre.copy({ imageModerations });
        }
        return pre;
      }, prev);
    }
    return prev;
  }

  static dedupAndSetVideoModerations(
    prev: Moderations,
    campaignCreativeVideoRequest: CampaignCreativeVideoRequest,
    elementsReviewedData: ElementsReviewedData,
    rejectReasons: RejectReasons,
  ): Moderations {
    if (Moderations.hasVideoElementModeration(elementsReviewedData)) {
      return campaignCreativeVideoRequest.assets.reduce((pre, asset) => {
        if (!pre.imageModerations.get(asset.videoId)) {
          const reviewStatus = elementsReviewedData.elementsReviewStatus?.video?.[asset.videoId];
          const reviewComment = elementsReviewedData.elementsReviewComment?.video?.[asset.videoId];
          const reviewInternalComment =
            elementsReviewedData.elementsReviewInternalComment?.video?.[asset.videoId];
          const rejectCodes = elementsReviewedData.rejectReasonCodes?.video?.[asset.videoId];
          const videoModerations = pre.videoModerations.set(
            asset.videoId,
            new ElementModeration(
              reviewStatus ?? notReviewedStatus,
              reviewComment ?? '',
              reviewInternalComment ?? '',
              rejectReasonCodeToValue(rejectReasons, rejectCodes),
            ),
          );
          return pre.copy({ videoModerations });
        }
        return pre;
      }, prev);
    }
    return prev;
  }

  static dedupAndSetCarouselImageModerations(
    prev: Moderations,
    campaignCreativeCarouselRequest: CampaignCreativeCarouselRequest,
    elementsReviewedData: ElementsReviewedData,
    rejectReasons: RejectReasons,
  ): Moderations {
    if (Moderations.hasImageElementModeration(elementsReviewedData)) {
      return campaignCreativeCarouselRequest.assets.reduce((pre, asset) => {
        if (!pre.imageModerations.get(asset.imageId)) {
          const reviewStatus = elementsReviewedData.elementsReviewStatus?.image?.[asset.imageId];
          const reviewComment = elementsReviewedData.elementsReviewComment?.image?.[asset.imageId];
          const reviewInternalComment =
            elementsReviewedData.elementsReviewInternalComment?.image?.[asset.imageId];
          const rejectCodes = elementsReviewedData.rejectReasonCodes?.image?.[asset.imageId];
          const imageModerations = pre.imageModerations.set(
            asset.imageId,
            new ElementModeration(
              reviewStatus ?? notReviewedStatus,
              reviewComment ?? '',
              reviewInternalComment ?? '',
              rejectReasonCodeToValue(rejectReasons, rejectCodes),
            ),
          );
          return pre.copy({ imageModerations });
        }
        return pre;
      }, prev);
    }
    return prev;
  }

  static dedupAndSetCarouselTitleModerations(
    prev: Moderations,
    campaignCreativeCarouselRequest: CampaignCreativeCarouselRequest,
    elementsReviewedData: ElementsReviewedData,
    rejectReasons: RejectReasons,
  ): Moderations {
    if (Moderations.hasCarouselTitleElementModeration(elementsReviewedData)) {
      return campaignCreativeCarouselRequest.assets.reduce((pre, asset) => {
        if (!pre.carouselTitleModerations.get(asset.imageTitle)) {
          const reviewStatus =
            elementsReviewedData.elementsReviewStatus?.carouselTitle?.[asset.imageTitle];
          const reviewComment =
            elementsReviewedData.elementsReviewComment?.carouselTitle?.[asset.imageTitle];
          const reviewInternalComment =
            elementsReviewedData.elementsReviewInternalComment?.carouselTitle?.[asset.imageTitle];
          const rejectCodes =
            elementsReviewedData.rejectReasonCodes?.carouselTitle?.[asset.imageTitle];
          const carouselTitleModerations = pre.carouselTitleModerations.set(
            asset.imageTitle,
            new ElementModeration(
              reviewStatus ?? notReviewedStatus,
              reviewComment ?? '',
              reviewInternalComment ?? '',
              rejectReasonCodeToValue(rejectReasons, rejectCodes),
            ),
          );
          return pre.copy({ carouselTitleModerations });
        }
        return pre;
      }, prev);
    }
    return prev;
  }

  private copy({
    sponsorModerations,
    titleModerations,
    bodyModerations,
    destinationModerations,
    imageModerations,
    videoModerations,
    carouselTitleModerations,
  }: {
    sponsorModerations?: ElementModerations<string>;
    titleModerations?: ElementModerations<string>;
    bodyModerations?: ElementModerations<string>;
    destinationModerations?: ElementModerations<string>;
    imageModerations?: ElementModerations<MediaId>;
    videoModerations?: ElementModerations<MediaId>;
    carouselTitleModerations?: ElementModerations<string>;
  }): Moderations {
    return new Moderations(
      sponsorModerations ?? this.sponsorModerations,
      titleModerations ?? this.titleModerations,
      bodyModerations ?? this.bodyModerations,
      destinationModerations ?? this.destinationModerations,
      imageModerations ?? this.imageModerations,
      videoModerations ?? this.videoModerations,
      carouselTitleModerations ?? this.carouselTitleModerations,
    );
  }

  findElementModerationPair(request: CampaignCreativeRequest): ElementModerationPair {
    const sponsorModeration = this.sponsorModerations.get(request.sponsor);
    const titleModeration = this.titleModerations.get(request.title);
    const bodyModeration = this.bodyModerations.get(request.body);
    const destinationModeration = this.destinationModerations.get(request.destination);
    const imageModerations = isCampaignCreativeImageRequest(request)
      ? this.imageModerations.getAll(request.assets.map((a) => a.imageId))
      : undefined;
    const videoModerations = isCampaignCreativeVideoRequest(request)
      ? this.videoModerations.getAll(request.assets.map((a) => a.videoId))
      : undefined;
    const carouselImageModerations = isCampaignCreativeCarouselRequest(request)
      ? this.imageModerations.getAll(request.assets.map((a) => a.imageId))
      : undefined;
    const carouselTitleModerations = isCampaignCreativeCarouselRequest(request)
      ? this.carouselTitleModerations.getAll(request.assets.map((a) => a.imageTitle))
      : undefined;

    return {
      sponsorModeration,
      titleModeration,
      bodyModeration,
      destinationModeration,
      imageModerations,
      videoModerations,
      carouselTitleModerations,
      carouselImageModerations,
    };
  }

  createCreativeModeration(
    request: CampaignCreativeRequest,
    creativeModeration: CreativeModeration,
    rejectReasons: RejectReasons,
  ): CreativeModeration {
    const moderationPair = this.findElementModerationPair(request);
    return CreativeModeration.create(moderationPair, creativeModeration, rejectReasons);
  }

  updateSponsor(sponsor: string, moderation: ElementModeration): Moderations {
    const sponsorModerations = this.sponsorModerations.set(sponsor, moderation);
    return this.copy({ sponsorModerations });
  }

  updateTitle(title: string, moderation: ElementModeration): Moderations {
    const titleModerations = this.titleModerations.set(title, moderation);
    return this.copy({ titleModerations });
  }

  updateBody(body: string, moderation: ElementModeration): Moderations {
    const bodyModerations = this.bodyModerations.set(body, moderation);
    return this.copy({ bodyModerations });
  }

  updateDestination(destination: string, moderation: ElementModeration): Moderations {
    const destinationModerations = this.destinationModerations.set(destination, moderation);
    return this.copy({ destinationModerations });
  }

  updateCarouselTitle(carouselTitle: string, moderation: ElementModeration): Moderations {
    const carouselTitleModerations = this.carouselTitleModerations.set(carouselTitle, moderation);
    return this.copy({ carouselTitleModerations });
  }

  updateImage(imageId: MediaId, moderation: ElementModeration): Moderations {
    const imageModerations = this.imageModerations.set(imageId, moderation);
    return this.copy({ imageModerations });
  }

  updateVideo(videoId: MediaId, moderation: ElementModeration): Moderations {
    const videoModerations = this.videoModerations.set(videoId, moderation);
    return this.copy({ videoModerations });
  }
}
