import type {
  DynamicAdsCatalog,
  DynamicAdsModerateParam,
  DynamicAdsSimilarAdsParam,
} from '@/pages/dynamicAds/data';
import { objectToParams } from '@/pages/utils/url';
import { moderationRequest as request } from '../utils/service';

export async function getDynamicAdsItems(params: any) {
  if (params.current) {
    // page number in the backend system starts from 0
    // eslint-disable-next-line no-param-reassign
    params.current -= 1;
  }
  return request(`dynamic-ads/items?${objectToParams(params)}`).then((res) => {
    return {
      total: res.total,
      data: res.adsItems,
    };
  });
}

export async function dynamicAdsModerate(params: DynamicAdsModerateParam[]) {
  return request(`dynamic-ads/moderate`, 'POST', params);
}

export async function getCatalogs(includePredeliveryCatalogs: boolean = false) {
  return request(
    `dynamic-ads/catalogs${includePredeliveryCatalogs ? '?includePredeliveryCatalogs=true' : ''}`,
    'GET',
  ).then((res) => {
    return res.catalogs.map((catalog: DynamicAdsCatalog) => ({
      label: catalog.text,
      value: catalog.name,
    }));
  });
}

export async function getDynamicAd(id: string) {
  return request(`dynamic-ad?id=${id}`).then((res) => {
    return res;
  });
}

export async function getSimilarAds(params: DynamicAdsSimilarAdsParam) {
  return request(`dynamic-ads/similar-ads?${objectToParams(params)}`).then((res) => {
    return res;
  });
}
