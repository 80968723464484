import { ModerationStatus } from '@/models/enums';
import { decodeTaskStandardAds } from '@/models/standardAds/decode';
import type {
  StandardAdsGroupedElements,
  StandardAdsTextElement,
} from '@/models/standardAds/element';
import { groupCampaignCreativeRequests } from '@/models/standardAds/element';
import type {
  CreativeModeration,
  CreativeModerations,
  CreativeReviewStatus,
  ElementModeration,
  ElementModerationPair,
  ElementModerations,
  ElementReviewStatus,
} from '@/models/standardAds/moderation';
import { ItemModerationHistories } from '@/models/standardAds/moderation';
import type { RejectReasons } from '@/models/standardAds/rejectReason';
import { convertToRejectReasonStructure } from '@/models/standardAds/rejectReason';
import type {
  CampaignCreativeRequest,
  ElementsReviewedContent,
  ElementsReviewedStatus,
  MediaId,
  RejectReasonCodes,
  TaskStandardAds,
} from '@/models/standardAds/reviewRequest';
import { sortItemsByDefaultOrder } from '@/models/standardAds/reviewRequest';
import type { ItemStandardAds, TaskHistory, TaskPool } from '@/models/task';
import { TaskStatus } from '@/models/task';
import type { ElementPassRateResponse, ModerationInfo } from '@/pages/services/moderationV3';
import { moderationV3APICommon } from '@/pages/services/moderationV3';
import { ModerationResultCache } from '@/pages/standard-ads/task-moderation/ModerationResultCache';
import { extractCommonPart, logError } from '@/utils/utils';
import { message } from 'antd';
import groupBy from 'lodash/groupBy';
import type { Effect, Reducer } from 'umi';
import { history } from 'umi';

const {
  getTasks,
  moderateItemsBatch,
  moderateItemsAndTasksBatch,
  updateTasks,
  escalateTasks,
  getTaskHistories,
  getTaskPoolByName,
  deescalateTasks,
  calcElementPassRate,
  rejectReasons,
} = moderationV3APICommon;

export interface StandardAdsModerationStateType {
  task: TaskStandardAds;
  taskHistories: TaskHistory[];
  taskPool: TaskPool;
  elements: StandardAdsGroupedElements;
  passRate: ElementPassRateResponse;
  itemModerationHistories: ItemModerationHistories;
  rejectReasons: RejectReasons;
}

export interface StandardAdsModerationModelType {
  namespace: 'standardAdsModeration';
  state: StandardAdsModerationStateType;
  effects: {
    fetch: Effect;
    submitModeration: Effect;
    escalateModeration: Effect;
    deescalateModeration: Effect;
    fetchRejectReasons: Effect;
  };
  reducers: {
    saveTask: Reducer<StandardAdsModerationStateType>;
    saveTaskHistories: Reducer<StandardAdsModerationStateType>;
    saveTaskPool: Reducer<StandardAdsModerationStateType>;
    saveElements: Reducer<StandardAdsModerationStateType>;
    savePassRate: Reducer<StandardAdsModerationStateType>;
    saveItemModerationHistories: Reducer<StandardAdsModerationStateType>;
    saveRejectReasons: Reducer<StandardAdsModerationStateType>;
  };
}

const DEFAULT_TASK: TaskStandardAds = {
  namespace: 'standard_ads',
  taskId: '-',
  taskPoolName: '-',
  taskStatus: TaskStatus.OPEN,
  assignee: '-',
  createdAt: '0',
  updatedAt: '0',
  itemCount: 0,
  items: [],
  payload: {
    taskPool: '-',
    reviewRequestId: '-',
    advertiserId: 0,
    advertiserName: '-',
    advertiserReviewNote: undefined,
    companyId: 0,
    companyName: '-',
    adCategoryId: 0,
    adCategoryName: undefined,
    adPolicyCategories: [],
    brandId: 0,
    brandName: '-',
    brandReviewNote: undefined,
    campaigns: {},
    campaignCreativeTypes: [],
    region: '-',
  },
  note: '-',
};

const DEFAULT_TASK_POOL: TaskPool = {
  id: '-',
  name: '-',
  description: '-',
  type: '-',
  taskUserGroup: {
    id: '-',
    name: '-',
    description: '-',
  },
  namespace: 'standard_ads',
  escalationPoolName: '-',
};

const DEFAULT_ELEMENTS: StandardAdsGroupedElements = {
  sponsor: [],
  title: [],
  body: [],
  destination: [],
  image: [],
  video: [],
  carouselTitle: [],
};

const DEFAULT_PASS_RATE: ElementPassRateResponse = {
  sponsors: new Map(),
  titles: new Map(),
  bodies: new Map(),
  media: new Map(),
  videos: new Map(),
  imageTitles: new Map(),
  destinations: new Map(),
};

const toModerationStatus = (reviewStatus: CreativeReviewStatus): ModerationStatus => {
  switch (reviewStatus) {
    case 'Approved':
      return ModerationStatus.APPROVED;
    case 'Rejected':
      return ModerationStatus.REJECTED;
    case 'Escalated':
      return ModerationStatus.ESCALATED;
    default:
      throw new Error('invalid review status');
  }
};

const buildRejectedCauses = (moderation: CreativeModeration, request: CampaignCreativeRequest) => {
  const rejectedCauses = [];

  if (moderation.sponsorRejected()) {
    rejectedCauses.push({ type: 'sponsor', value: request.sponsor });
  }

  if (moderation.titleRejected()) {
    rejectedCauses.push({ type: 'title', value: request.title });
  }

  if (moderation.bodyRejected()) {
    rejectedCauses.push({ type: 'body', value: request.body });
  }

  if (moderation.destinationRejected()) {
    rejectedCauses.push({ type: 'destination', value: request.destination });
  }

  moderation.rejectedImageIds().forEach((imageId) => {
    rejectedCauses.push({ type: 'image', value: String(imageId) });
  });

  moderation.rejectedVideoIds().forEach((videoId) => {
    rejectedCauses.push({ type: 'video', value: String(videoId) });
  });

  moderation.rejectedCarouselImageIds().forEach((imageId) => {
    rejectedCauses.push({ type: 'image', value: String(imageId) });
  });

  moderation.rejectedCarouselTitles().forEach((title) => {
    rejectedCauses.push({ type: 'image_title', value: title });
  });

  return rejectedCauses;
};

const notReviewedEmpty = (
  elementsReviewed: ElementsReviewedStatus | ElementsReviewedContent,
): boolean => {
  return (
    !!elementsReviewed.sponsor ||
    !!elementsReviewed.title ||
    !!elementsReviewed.body ||
    !!elementsReviewed.destination ||
    !!elementsReviewed.image ||
    !!elementsReviewed.video ||
    !!elementsReviewed.carouselTitle
  );
};

const notRejectedEmpty = (rejectReasonCodes: RejectReasonCodes): boolean => {
  return (
    !!rejectReasonCodes.creative ||
    !!rejectReasonCodes.sponsor ||
    !!rejectReasonCodes.title ||
    !!rejectReasonCodes.body ||
    !!rejectReasonCodes.destination ||
    !!rejectReasonCodes.image ||
    !!rejectReasonCodes.video ||
    !!rejectReasonCodes.carouselTitle
  );
};

const extractElementReviewStatus = (
  elementModeration: ElementModeration | undefined,
): ElementReviewStatus | undefined => {
  return !!elementModeration?.isReviewed() ? elementModeration.reviewStatus : undefined;
};

const extractMediaElementReviewStatus = (
  mediaModerations: ElementModerations<MediaId> | undefined,
): Map<number, ElementReviewStatus> | undefined => {
  return !!mediaModerations && mediaModerations.hasReviewed()
    ? new Map(
        mediaModerations
          .getModerationEntries()
          .filter((moderationEntry) => !!moderationEntry[1]?.isReviewed())
          .map((moderationEntry) => [moderationEntry[0], moderationEntry[1].reviewStatus]),
      )
    : undefined;
};

const extractCarouselTitleElementReviewStatus = (
  carouselTitleModerations: ElementModerations<string> | undefined,
): Map<string, ElementReviewStatus> | undefined => {
  return !!carouselTitleModerations && carouselTitleModerations.hasReviewed()
    ? new Map(
        carouselTitleModerations
          .getModerationEntries()
          .filter((moderationEntry) => !!moderationEntry[1]?.isReviewed())
          .map((moderationEntry) => [moderationEntry[0], moderationEntry[1].reviewStatus]),
      )
    : undefined;
};

const extractElementsReviewStatus = (
  elementModerationPair: ElementModerationPair,
): ElementsReviewedStatus | undefined => {
  const elementsReviewStatus: ElementsReviewedStatus = {};
  elementsReviewStatus.sponsor = extractElementReviewStatus(
    elementModerationPair.sponsorModeration,
  );
  elementsReviewStatus.title = extractElementReviewStatus(elementModerationPair.titleModeration);
  elementsReviewStatus.body = extractElementReviewStatus(elementModerationPair.bodyModeration);
  elementsReviewStatus.destination = extractElementReviewStatus(
    elementModerationPair.destinationModeration,
  );
  elementsReviewStatus.image = extractMediaElementReviewStatus(
    elementModerationPair.imageModerations || elementModerationPair.carouselImageModerations,
  );
  elementsReviewStatus.video = extractMediaElementReviewStatus(
    elementModerationPair.videoModerations,
  );
  elementsReviewStatus.carouselTitle = extractCarouselTitleElementReviewStatus(
    elementModerationPair.carouselTitleModerations,
  );
  return notReviewedEmpty(elementsReviewStatus) ? elementsReviewStatus : undefined;
};

const extractElementReviewComment = (
  elementModeration: ElementModeration | undefined,
): string | undefined => {
  return elementModeration?.reviewComment;
};

const extractMediaElementReviewComment = (
  mediaModerations: ElementModerations<MediaId> | undefined,
): Map<number, string> | undefined => {
  return !!mediaModerations && mediaModerations.hasReviewedComment()
    ? new Map(
        mediaModerations
          .getModerationEntries()
          .filter((moderationEntry) => !!moderationEntry[1]?.reviewComment)
          .map((moderationEntry) => [moderationEntry[0], moderationEntry[1].reviewComment]),
      )
    : undefined;
};

const extractCarouselTitleElementReviewComment = (
  carouselTitleModerations: ElementModerations<string> | undefined,
): Map<string, string> | undefined => {
  return !!carouselTitleModerations && carouselTitleModerations.hasReviewedComment()
    ? new Map(
        carouselTitleModerations
          .getModerationEntries()
          .filter((moderationEntry) => !!moderationEntry[1]?.reviewComment)
          .map((moderationEntry) => [moderationEntry[0], moderationEntry[1].reviewComment]),
      )
    : undefined;
};

const extractElementsReviewComment = (
  elementModerationPair: ElementModerationPair,
): ElementsReviewedContent | undefined => {
  const elementsReviewComment: ElementsReviewedContent = {};
  elementsReviewComment.sponsor = extractElementReviewComment(
    elementModerationPair.sponsorModeration,
  );
  elementsReviewComment.title = extractElementReviewComment(elementModerationPair.titleModeration);
  elementsReviewComment.body = extractElementReviewComment(elementModerationPair.bodyModeration);
  elementsReviewComment.destination = extractElementReviewComment(
    elementModerationPair.destinationModeration,
  );
  elementsReviewComment.image = extractMediaElementReviewComment(
    elementModerationPair.imageModerations || elementModerationPair.carouselImageModerations,
  );
  elementsReviewComment.video = extractMediaElementReviewComment(
    elementModerationPair.videoModerations,
  );
  elementsReviewComment.carouselTitle = extractCarouselTitleElementReviewComment(
    elementModerationPair.carouselTitleModerations,
  );

  return notReviewedEmpty(elementsReviewComment) ? elementsReviewComment : undefined;
};

const extractElementReviewInternalComment = (
  elementModeration: ElementModeration | undefined,
): string | undefined => {
  return elementModeration?.internalComment;
};

const extractMediaElementReviewInternalComment = (
  mediaModerations: ElementModerations<MediaId> | undefined,
): Map<number, string> | undefined => {
  return !!mediaModerations && mediaModerations.hasReviewedInternalComment()
    ? new Map(
        mediaModerations
          .getModerationEntries()
          .filter((moderationEntry) => !!moderationEntry[1]?.internalComment)
          .map((moderationEntry) => [moderationEntry[0], moderationEntry[1].internalComment]),
      )
    : undefined;
};

const extractCarouselTitleElementReviewInternalComment = (
  carouselTitleModerations: ElementModerations<string> | undefined,
): Map<string, string> | undefined => {
  return !!carouselTitleModerations && carouselTitleModerations.hasReviewedInternalComment()
    ? new Map(
        carouselTitleModerations
          .getModerationEntries()
          .filter((moderationEntry) => !!moderationEntry[1]?.internalComment)
          .map((moderationEntry) => [moderationEntry[0], moderationEntry[1].internalComment]),
      )
    : undefined;
};

const extractElementsReviewInternalComment = (
  elementModerationPair: ElementModerationPair,
): ElementsReviewedContent | undefined => {
  const elementsReviewInternalComment: ElementsReviewedContent = {};
  elementsReviewInternalComment.sponsor = extractElementReviewInternalComment(
    elementModerationPair.sponsorModeration,
  );
  elementsReviewInternalComment.title = extractElementReviewInternalComment(
    elementModerationPair.titleModeration,
  );
  elementsReviewInternalComment.body = extractElementReviewInternalComment(
    elementModerationPair.bodyModeration,
  );
  elementsReviewInternalComment.destination = extractElementReviewInternalComment(
    elementModerationPair.destinationModeration,
  );
  elementsReviewInternalComment.image = extractMediaElementReviewInternalComment(
    elementModerationPair.imageModerations || elementModerationPair.carouselImageModerations,
  );
  elementsReviewInternalComment.video = extractMediaElementReviewInternalComment(
    elementModerationPair.videoModerations,
  );
  elementsReviewInternalComment.carouselTitle = extractCarouselTitleElementReviewInternalComment(
    elementModerationPair.carouselTitleModerations,
  );
  return notReviewedEmpty(elementsReviewInternalComment)
    ? elementsReviewInternalComment
    : undefined;
};

type RejectReason = [string, string, string?];

const getRejectReason = (rejectReason: RejectReason): string => {
  const [, usRejectReason, jpRejectReason] = rejectReason;
  return jpRejectReason ?? usRejectReason;
};

const extractRejectReasonCodes = (
  creativeModeration: CreativeModeration,
): RejectReasonCodes | undefined => {
  const rejectReasonCodes: RejectReasonCodes = {};
  const elementModerationPair = creativeModeration.elementModerationPair;
  rejectReasonCodes.creative = creativeModeration.creativeRejectCodes.map((rejectReason) =>
    getRejectReason(rejectReason),
  );
  rejectReasonCodes.sponsor = elementModerationPair.sponsorModeration?.rejectCodes.map(
    (rejectReason) => getRejectReason(rejectReason),
  );
  rejectReasonCodes.title = elementModerationPair.titleModeration?.rejectCodes.map((rejectReason) =>
    getRejectReason(rejectReason),
  );
  rejectReasonCodes.body = elementModerationPair.bodyModeration?.rejectCodes.map((rejectReason) =>
    getRejectReason(rejectReason),
  );
  rejectReasonCodes.destination = elementModerationPair.destinationModeration?.rejectCodes.map(
    (rejectReason) => getRejectReason(rejectReason),
  );
  const images =
    elementModerationPair.imageModerations || elementModerationPair.carouselImageModerations;
  rejectReasonCodes.image =
    !!images && images.hasReviewed()
      ? new Map(
          images
            .getModerationEntries()
            .filter((moderationEntry) => !!moderationEntry[1]?.isReviewed())
            .map((moderationEntry) => [
              moderationEntry[0].toString(),
              moderationEntry[1].rejectCodes.map((rejectReason) => getRejectReason(rejectReason)),
            ]),
        )
      : undefined;
  rejectReasonCodes.video =
    !!elementModerationPair.videoModerations && elementModerationPair.videoModerations.hasReviewed()
      ? new Map(
          elementModerationPair.videoModerations
            .getModerationEntries()
            .filter((moderationEntry) => !!moderationEntry[1]?.isReviewed())
            .map((moderationEntry) => [
              moderationEntry[0].toString(),
              moderationEntry[1].rejectCodes.map((rejectReason) => getRejectReason(rejectReason)),
            ]),
        )
      : undefined;
  rejectReasonCodes.carouselTitle =
    !!elementModerationPair.carouselTitleModerations &&
    elementModerationPair.carouselTitleModerations.hasReviewed()
      ? new Map(
          elementModerationPair.carouselTitleModerations
            .getModerationEntries()
            .filter((moderationEntry) => !!moderationEntry[1]?.isReviewed())
            .map((moderationEntry) => [
              moderationEntry[0],
              moderationEntry[1].rejectCodes.map((rejectReason) => getRejectReason(rejectReason)),
            ]),
        )
      : undefined;
  return notRejectedEmpty(rejectReasonCodes) ? rejectReasonCodes : undefined;
};

const replacer = (key: any, value: any): any => {
  if (value instanceof Map) {
    return Object.fromEntries(value);
  } else {
    return value;
  }
};

const buildModerationInfo = (
  creativeModerations: CreativeModerations,
  task: TaskStandardAds,
): ModerationInfo[] => {
  const items: ItemStandardAds<CampaignCreativeRequest>[] = task.items ?? [];
  return items
    .filter((item) => !item.isDeleted)
    .map((item) => {
      const key = item.payload.key;
      const creativeModeration = creativeModerations.get(key);
      if (creativeModeration === null || creativeModeration === undefined) {
        throw new Error('moderation corresponding to campaign creative not found');
      }
      const elementsReviewedStatus = !!creativeModeration.elementModerationPair
        ? extractElementsReviewStatus(creativeModeration.elementModerationPair)
        : undefined;
      const elementsReviewedComment = !!creativeModeration.elementModerationPair
        ? extractElementsReviewComment(creativeModeration.elementModerationPair)
        : undefined;
      const elementsReviewedInternalComment = !!creativeModeration.elementModerationPair
        ? extractElementsReviewInternalComment(creativeModeration.elementModerationPair)
        : undefined;
      const rejectReasonCodes = extractRejectReasonCodes(creativeModeration);

      const extra = {
        rejectedCauses: buildRejectedCauses(creativeModeration, item.payload),
        elementsReviewStatus: elementsReviewedStatus,
        elementsReviewComment: elementsReviewedComment,
        elementsReviewInternalComment: elementsReviewedInternalComment,
        rejectReasonCodes: rejectReasonCodes,
      };

      return {
        itemId: item.itemId,
        moderationStatus: toModerationStatus(creativeModeration.reviewStatus),
        moderationReason: creativeModeration.reviewComment,
        moderationNote: creativeModeration.internalComment,
        extra: JSON.stringify(extra, replacer),
        taskId: task.taskId,
      };
    });
};

const StandardAdsModerationModel: StandardAdsModerationModelType = {
  namespace: 'standardAdsModeration',

  state: {
    task: { ...DEFAULT_TASK },
    taskHistories: [],
    taskPool: { ...DEFAULT_TASK_POOL },
    elements: { ...DEFAULT_ELEMENTS },
    passRate: { ...DEFAULT_PASS_RATE },
    itemModerationHistories: ItemModerationHistories.create(),
    rejectReasons: { reasons: new Map() },
  },

  effects: {
    *fetch({ payload: { taskId: taskId, namespace: namespace } }, { all, call, put }) {
      try {
        const [tasksResponse, historiesResponse] = yield all([
          call(getTasks, namespace, {
            taskIds: [taskId],
            includeItemDetails: true,
            includeSupplyProtection: true,
          }),
          call(getTaskHistories, namespace, taskId),
        ]);

        if (tasksResponse.status >= 400)
          throw new Error(
            `getTasks(taskId = ${taskId}) failed with ${tasksResponse.status}: ${tasksResponse.data}`,
          );

        if (historiesResponse.status >= 400)
          throw new Error(
            `getTaskHistories(taskId = ${taskId}) failed with ${historiesResponse.status}: ${historiesResponse.data}`,
          );

        if (!(tasksResponse?.data !== null && tasksResponse?.data instanceof Array))
          throw new Error(`Invalid response of getTasks(taskId=${taskId}): ${tasksResponse.data}`);

        if (!(historiesResponse?.data !== null && historiesResponse?.data instanceof Array))
          throw new Error(
            `Invalid response of getTaskHistories(taskId=${taskId}): ${historiesResponse.data}`,
          );

        const tasks = tasksResponse.data;
        const taskHistories = historiesResponse.data;

        if (tasks.length === 0) {
          throw new Error(`Not Found: taskId=${taskId}`);
        }
        const task = tasks[0];
        const decodedTask = decodeTaskStandardAds(task);

        const [taskPoolResponse] = yield all([
          call(getTaskPoolByName, namespace, task.taskPoolName),
        ]);

        if (taskPoolResponse.status >= 400)
          throw new Error(
            `getTaskPoolByName(${task.taskPoolName}) failed with ${taskPoolResponse.status}: ${taskPoolResponse.data}`,
          );

        const taskPool = taskPoolResponse.data;

        const filteredItems = sortItemsByDefaultOrder(
          decodedTask.items?.filter((item) => !item.isDeleted) ?? [],
        );
        const moderationHistories = ItemModerationHistories.fromItems(filteredItems);

        // calculate pass rate parameters
        const elements = groupCampaignCreativeRequests(filteredItems);
        const groupedDestinations: Record<string, StandardAdsTextElement[]> = groupBy(
          elements.destination,
          (item) => extractCommonPart(item.content),
        );

        try {
          const [elementPassRateResponse] = yield all([
            call(calcElementPassRate, namespace, {
              filter: {
                advertiserId: decodedTask.payload.advertiserId,
                brandId: decodedTask.payload.brandId,
              },
              sponsors: elements.sponsor.map((_: { content: any }) => _.content),
              titles: elements.title.map((_: { content: any }) => _.content),
              bodies: elements.body.map((_: { content: any }) => _.content),
              media: elements.image.map((_: { imageAsset: { imageId: number } }) =>
                _.imageAsset.imageId.toFixed(),
              ),
              videos: elements.video.map((_: { videoAsset: { videoId: number } }) =>
                _.videoAsset.videoId.toFixed(),
              ),
              imageTitles: elements.carouselTitle.map((_: { content: any }) => _.content),
              destinations: Object.entries(groupedDestinations).map(([commonPart]) => commonPart),
            }),
          ]);

          if (elementPassRateResponse.status >= 400) {
            throw new Error(
              `call to get pass rate failed with ${elementPassRateResponse.status}: ${elementPassRateResponse}`,
            );
          }

          yield all([
            put({
              type: 'savePassRate',
              payload: elementPassRateResponse,
            }),
          ]);
        } catch (e) {
          message.error('Failed to fetch pass rate: ' + e);
          const context = e instanceof Error ? e : null;
          logError('Failed to fetch pass rate: ' + e, context);
        }

        yield all([
          put({
            type: 'saveTask',
            payload: decodedTask,
          }),
          put({
            type: 'saveTaskHistories',
            payload: taskHistories,
          }),
          put({
            type: 'saveTaskPool',
            payload: taskPool,
          }),
          put({
            type: 'saveElements',
            payload: elements,
          }),
          put({
            type: 'saveItemModerationHistories',
            payload: moderationHistories,
          }),
        ]);
      } catch (e) {
        message.error('Failed to fetch task: ' + e);
        const context = e instanceof Error ? e : null;
        logError('Failed to fetch task: ' + e, context);
      }
    },

    *submitModeration({ payload }, { call }) {
      try {
        const task = payload.task as TaskStandardAds;
        const creativeModerations = payload.creativeModerations as CreativeModerations;
        const { namespace, pathNamespace } = payload;

        const moderations: ModerationInfo[] = buildModerationInfo(creativeModerations, task);
        if (moderations.length <= 256) {
          try {
            // maximum batch size is 256
            const res = yield call(moderateItemsAndTasksBatch, namespace, [task.taskId], {
              data: moderations,
              assignee: null,
              taskStatus: 'CLOSED',
            });
            if (res.status >= 400)
              throw new Error(
                `moderateItemsAndTasksBatch() failed with ${res.status}: ${res.data}`,
              );
          } catch (e) {
            const options = e instanceof Error ? { cause: e } : {};
            throw new Error('Failed to update item: ' + e, options);
          }
        } else {
          try {
            while (moderations.length > 0) {
              // maximum batch size is 256
              const data = moderations.splice(0, 256);
              const res = yield call(moderateItemsBatch, namespace, { data });
              if (res.status >= 400)
                throw new Error(`moderateItemsBatch() failed with ${res.status}: ${res.data}`);
            }
          } catch (e) {
            const options = e instanceof Error ? { cause: e } : {};
            throw new Error('Failed to update item: ' + e, options);
          }

          try {
            const res = yield call(updateTasks, namespace, {
              taskIds: [task.taskId],
              taskStatus: 'CLOSED',
            });
            if (res.status >= 400)
              throw new Error(`updateTasks(${task.taskId}) failed with ${res.status}: ${res.data}`);
          } catch (e) {
            const options = e instanceof Error ? { cause: e } : {};
            throw new Error('Failed to update task: ' + e, options);
          }
        }

        message.success('Successfully submitted the moderation result.');
        ModerationResultCache.delete(task.taskId);
        history.push(
          `/moderation/${pathNamespace}/tasks?reviewWorkflow=${task.payload.reviewWorkflow}`,
        );
      } catch (e) {
        message.error('Failed to submit moderation: ' + e);
        const context = e instanceof Error ? e : null;
        logError('Failed to submit moderation: ' + e, context);
      }
    },

    *escalateModeration({ payload }, { call }) {
      try {
        const task = payload.task as TaskStandardAds;
        const creativeModerations = payload.creativeModerations as CreativeModerations;
        const { namespace, pathNamespace } = payload;

        const moderations: ModerationInfo[] = buildModerationInfo(creativeModerations, task);

        try {
          while (moderations.length > 0) {
            // maximum batch size is 256
            const data = moderations.splice(0, 255);
            const res = yield call(moderateItemsBatch, namespace, { data });
            if (res.status >= 400)
              throw new Error(`moderateItemsBatch() failed with ${res.status}: ${res.data}`);
          }
        } catch (e) {
          const options = e instanceof Error ? { cause: e } : {};
          throw new Error('Failed to update item: ' + e, options);
        }

        try {
          const res = yield call(escalateTasks, namespace, {
            taskIds: [task.taskId],
            note: payload.note,
          });
          if (res.status >= 400)
            throw new Error(`escalateTasks() failed with ${res.status}: ${res.data}`);
        } catch (e) {
          const options = e instanceof Error ? { cause: e } : {};
          throw new Error('Failed to escalate task: ' + e, options);
        }

        message.success('Successfully escalated the task.');
        ModerationResultCache.delete(task.taskId);
        history.push(
          `/moderation/${pathNamespace}/tasks?reviewWorkflow=${task.payload.reviewWorkflow}`,
        );
      } catch (e) {
        message.error('Failed to escalate: ' + e);
        const context = e instanceof Error ? e : null;
        logError('Failed to escalate the task: ' + e, context);
      }
    },

    deescalateModeration: function* ({ payload }, { call }) {
      try {
        const task = payload.task as TaskStandardAds;
        const creativeModerations = payload.creativeModerations as CreativeModerations;
        const { namespace, pathNamespace } = payload;

        const moderations: ModerationInfo[] = buildModerationInfo(creativeModerations, task);

        try {
          while (moderations.length > 0) {
            // maximum batch size is 256
            const data = moderations.splice(0, 255);
            const res = yield call(moderateItemsBatch, namespace, { data });
            if (res.status >= 400)
              throw new Error(`moderateItemsBatch() failed with ${res.status}: ${res.data}`);
          }
        } catch (e) {
          const options = e instanceof Error ? { cause: e } : {};
          throw new Error('Failed to update item: ' + e, options);
        }

        try {
          const res = yield call(deescalateTasks, namespace, {
            taskIds: [task.taskId],
            deescalationAction: payload.deescalationAction,
            note: payload.note,
          });
          if (res.status >= 400)
            throw new Error(`deescalateTasks() failed with ${res.status}: ${res.data}`);
        } catch (e) {
          const options = e instanceof Error ? { cause: e } : {};
          throw new Error('Failed to de-escalate task: ' + e, options);
        }

        message.success('Successfully de-escalated the task.');
        ModerationResultCache.delete(task.taskId);
        history.push(
          `/moderation/${pathNamespace}/tasks?reviewWorkflow=${task.payload.reviewWorkflow}`,
        );
      } catch (e) {
        message.error('Failed to de-escalate: ' + e);
        const context = e instanceof Error ? e : null;
        logError('Failed to de-escalate the task: ' + e, context);
      }
    },

    *fetchRejectReasons({ payload: { namespace: namespace, region: region } }, { all, call, put }) {
      try {
        const locale = region === 'US' ? 'en-US' : 'ja-JP';
        const regionParam = region === 'US' ? region : 'JP';
        const [rejectReasonsResponse] = yield all([
          call(rejectReasons, namespace, regionParam, locale),
        ]);

        if (rejectReasonsResponse.status >= 400)
          throw new Error(
            `getRejectReasons(${namespace}) failed with ${rejectReasonsResponse.status}: ${rejectReasonsResponse.data}`,
          );

        const rejectReasonList = convertToRejectReasonStructure(rejectReasonsResponse, locale);
        yield all([
          put({
            type: 'saveRejectReasons',
            payload: rejectReasonList,
          }),
        ]);
      } catch (e) {
        message.error('Failed to fetch reject reasons: ' + e);
        const context = e instanceof Error ? e : null;
        logError('Failed to fetch reject reasons: ' + e, context);
      }
    },
  },

  reducers: {
    saveTask(state, action) {
      return {
        ...state,
        task: action.payload ?? [],
      };
    },
    saveTaskHistories(state, action) {
      return {
        ...state,
        taskHistories: action.payload ?? [],
      };
    },
    saveTaskPool(state, action) {
      return {
        ...state,
        taskPool: action.payload ?? [],
      };
    },
    saveElements(state, action) {
      return {
        ...state,
        elements: action.payload,
      };
    },
    savePassRate(state, action) {
      return {
        ...state,
        passRate: action.payload,
      };
    },
    saveItemModerationHistories(state, action) {
      return {
        ...state,
        itemModerationHistories: action.payload,
      };
    },
    saveRejectReasons(state, action) {
      return {
        ...state,
        rejectReasons: action.payload,
      };
    },
  },
};

export default StandardAdsModerationModel;
