export default {
  'menu.DocomoContent': 'SFDコンテンツ',
  'sfd.article.reviewResult': '審査結果',
  'sfd.article.search.mode.simple': '簡易検索',
  'sfd.article.search.mode.advanced': '詳細検索',
  'sfd.article.reviewResult.All': 'すべて',
  'sfd.article.reviewResult.Unchecked': '未審査',
  'sfd.article.reviewResult.Checked': '審査OK',
  'sfd.article.reviewResult.Pending': '保留中',
  'sfd.article.reviewResult.Marked': '審査NG',
  'sfd.article.reviewResult.Stopped': '非表示',
  'sfd.article.channel': 'チャンネル',
  'sfd.article.publisher': '媒体',
  'sfd.article.category': 'カテゴリー',
  'sfd.article.channel.search.placeholder': 'チャンネルを選択する',
  'sfd.article.publisher.search.placeholder': '媒体を選択する',
  'sfd.article.category.search.placeholder': 'カテゴリーを選択する',
  'sfd.article.lastUpdated': '最終更新',
  'sfd.article.keyword.search.placeholder': 'キーワードまたURL',
  'sfd.article.keyword.search': '検索テキスト',
  'sfd.article.search': '検索',
  'sfd.article.reset': 'リセット',
  'sfd.article.orderBy': '並び替え',
  'sfd.article.lastUpdatedTime': '最終更新日時',
  'sfd.article.desc': '降順',
  'sfd.article.asc': '昇順',
  'sfd.article.ngReason': 'NG理由',
  'sfd.article.ngReason.placeholder': 'NG理由を選択する',
  'sfd.article.comment': 'コメント',
  'sfd.article.comment.placeholder': 'コメント有無を選択する',
  'sfd.article.comment.exist': 'コメントあり',
  'sfd.article.comment.noExist': 'コメントなし',
  'sfd.article.searchFor': '[任意]テキスト検索対象',
  'sfd.article.selectObject': '検索対象を絞り込む',
  'sfd.article.title': 'タイトル',
  'sfd.article.content': '本文冒頭',
  'sfd.article.url': 'URL',
  'sfd.article.published': '公開日時',
  'sfd.article.preview.loading': '読み込み中...',
  'sfd.article.moderation.comment.placeholder': 'コメントを入力する',
  'sfd.article.moderation.button.check': '審査OK',
  'sfd.article.moderation.button.mark': '審査NG',
  'sfd.article.moderation.button.stop': '非表示',
  'sfd.article.moderation.button.pend': '保留',
  'sfd.article.moderation.success.message': '処理が完了しました',
  'sfd.article.moderation.button.reviewAll': '一括審査',
  'sfd.article.moderation.reviewAll.dialog.confirm': '確認',
  'sfd.article.moderation.reviewAll.dialog.cancel': 'キャンセル',
  'sfd.article.ngReason.eroticImage': 'NG画像（エロ）',
  'sfd.article.ngReason.grotesqueImage': 'NG画像（グロ）',
  'sfd.article.ngReason.lowResolutionImage': '低解像度',
  'sfd.article.ngReason.pastArticle': '過去記事',
  'sfd.article.ngReason.others': 'その他',
  'sfd_content.article.updated': '記事が更新されましたので、このページを再読み込みしてください',
  'sfd_content.article.reviewed':
    '記事は他の担当者によってレビューされていましたので、このページを再読み込みしてください',
  'sfd_content.article.updated.show.only': '更新した記事だけを表示する',
  'sfd_content.article.export': 'エクスポート',
  'sfd_content.article.export.button.request': 'リクエスト',
  'sfd_content.article.export.button.reset': 'リセット',
  'sfd_content.article.export.reviewTimePeriod': '審査期間',
  'sfd_content.article.export.status': 'ステータス',
  'sfd_content.article.export.status.pending': '処理中',
  'sfd_content.article.export.status.readyForDownload': '準備完了',
  'sfd_content.article.export.status.failed': '失敗',
  'sfd_content.article.export.status.expiredTime': '有効期限',
  'sfd_content.article.export.status.requestTime': 'リクエスト日時',
  'sfd_content.article.export.status.allRequestReports': 'リクエストしたレポート',
};
