import type {
  ChannelWithPolicyCategory,
  GetChannelWithPolicyCategoryResponse,
} from '@/pages/services/adChannel';
import {
  getAllAdChannelsWithPolicyCategories,
  updateAdChannelWithPolicyCategory,
} from '@/pages/services/adChannel';
import { GetPolicyCategoryResponse } from '@/pages/services/adPolicyCategory';
import type { Effect, Reducer } from 'umi';

export interface AdChannelState {
  channels: ChannelWithPolicyCategory[];
}

export interface AdChannelModelType {
  namespace: 'adChannel';
  state: AdChannelState;
  effects: {
    fetch: Effect;
    update: Effect;
  };
  reducers: {
    save: Reducer<AdChannelState>;
  };
}

const namespace = 'STANDARD_ADS';

const ChannelModel: AdChannelModelType = {
  namespace: 'adChannel',

  state: {
    channels: [],
  },

  effects: {
    *fetch(_, { call, put }) {
      const response: GetChannelWithPolicyCategoryResponse = yield call(
        getAllAdChannelsWithPolicyCategories,
        namespace,
      );
      yield put({
        type: 'save',
        payload: response.list,
      });

      return response;
    },

    *update({ payload }, { call, put, select }) {
      const response: ChannelWithPolicyCategory = yield call(
        updateAdChannelWithPolicyCategory,
        namespace,
        payload,
      );

      const { channelId, categoryIds } = payload;

      const channels: ChannelWithPolicyCategory[] = yield select(
        (state) => state.adChannel.channels,
      );
      const allCategories: GetPolicyCategoryResponse[] = yield select(
        (state) => state.adPolicyCategory.categories,
      );

      const index = channels.findIndex((channel) => channel.channel.id === channelId);
      const categories = categoryIds.map((id: number) => {
        const category = allCategories.find((c) => c.id === id);
        return {
          id,
          nameJp: category?.nameJp || '',
          nameEn: category?.nameEn || '',
        };
      });

      channels[index] = {
        ...channels[index],
        categories,
      };

      yield put({
        type: 'save',
        payload: [...channels],
      });

      return response;
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        channels: payload,
      };
    },
  },
};

export default ChannelModel;
