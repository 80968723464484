export interface DynamicAdsItem {
  id: string;
  itemId: string;
  moderationStatus: string;
  moderationStatusSfd: string;
  title: string;
  description: string;
  url: string;
  imageURL: string;
  category: string;
  catalog: string;
  catalogText: string | undefined;
  suspiciousScore: string;
  vimp: string;
  click: string;
  reject: string;
  shopId: string;
  price: string;
  freeShippingLabel: string | null;
  similarScore: string;
  moderator: string;
  moderationReason: string;
  moderationNote: string;
  moderatedAt: string;
  userActionSfd: {
    click: string;
    vimp: string;
    reject: string;
  };
}

export interface DynamicAdsModeration {
  items: DynamicAdsItem[];
  moderationStatus?: string;
  moderationReason?: string;
}

export interface DynamicAdsCatalog {
  name: string; // The unique label for the catalog.
  text: string | Undefined; // The human-readable text. Preferred to display this if available.
  approxAdsCount: string;
}

export enum DynamicAdsModerationStatus {
  UNPROCESSED = 'UNPROCESSED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum APP {
  SN = 'SN',
  SFD = 'SFD',
}

export interface DynamicAdsModerateParam {
  ids: string;
  moderationStatus: string;
  moderationReason?: string;
  moderationStatusSfd?: string;
}

export interface DynamicAdsSimilarAdsParam {
  ids: string[];
  topN: number;
  catalogs: string[];
}
